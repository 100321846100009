@value black, white, midGrey, lightGrey1_r, lightGrey1_g, lightGrey1_b from "@depop/web-ui-kit/theme/default/colors.module.css";
@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .pill {
    display: inline-flex;
    background-color: white;
    color: black;
    border: 1px solid midGrey;
    flex-shrink: 0;
    text-decoration: none;
    padding: sm md;
    border-radius: 32px;
    cursor: pointer;
    font-family: var(--font-GtAmericaExtended);
    font-size: 14px;

    &:first-of-type {
      margin-left: md;
    }

    &:hover {
      background-color: rgba(lightGrey1_r, lightGrey1_g, lightGrey1_b, 0.6);
    }

    @media breakpointMdUp {
      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  .pillActive {
    background-color: black;
    color: white;

    &:hover {
      background-color: midGrey;
      border-color: midGrey;
      color: white;
    }
  }
}
