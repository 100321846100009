@value xs, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value black, midGrey, a11yYellow from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .breadcrumbsContainer {
    margin-bottom: md;
    width: 100%;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .breadcrumbsScrollableContainer {
    display: flex;
    flex-direction: row-reverse;
    overflow: auto;
  }

  .breadcrumbsList {
    display: flex;
    flex-grow: 1;
    flex-wrap: nowrap;
    list-style: none;
  }

  .breadcrumbItem {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-right: xs;
  }

  .breadcrumbLink {
    &:focus {
      box-shadow: inset 0 0 0 2px a11yYellow;

      /* Visible in Windows high-contrast themes: https://stackoverflow.com/a/52616313 */
      outline: 4px dotted transparent;
    }
  }

  .breadcrumbLinkText {
    color: midGrey;

    &:hover {
      text-decoration: underline;
    }
  }

  .breadcrumbLabel {
    margin-right: xs;
  }

  .breadcrumbActiveLabel {
    color: black;
  }
}
