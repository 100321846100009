@value xs, sm from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value green from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .container {
    color: green;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: sm;
    gap: xs;

    @media breakpointMdDown {
      margin-top: 0;
    }
  }

  .shippingMessage {
    color: green;
  }
}
