@value alto, white from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .videoContainer {
    position: relative;
    width: 100%;
    min-width: 100%;
    padding-top: 100%;
    cursor: pointer;

    video {
      width: 100%;
      background-color: alto;
    }

    &:hover button {
      opacity: 1;
    }
  }

  .videoBackground {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .videoSpinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 96px;
    height: 96px;
    margin-top: -48px;
    margin-left: -48px;
  }

  .playButtonBase {
    border: none;
    background: none;
    opacity: 0.6;
    outline: 0;
    cursor: pointer;
    transition: 0.2s all ease-in;

    &:hover {
      opacity: 1;
    }

    svg {
      width: 100%;
      margin: 0;
      padding: 0;
      border: 0;
      display: block;
    }
  }

  .videoPlayButton {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    text-align: center;
    position: absolute;
    inset: 0;
  }

  .audioPlayButton {
    position: absolute;
    top: 2.5%;
    left: 5%;
    cursor: pointer;
    color: white;
  }
}
