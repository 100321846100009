@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: sm;
    margin-bottom: md;
  }
}
