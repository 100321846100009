@value lightGrey2 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value md, sm from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .container {
    display: flex;
    flex-direction: column;
    text-align: center;
    border-bottom: 1px solid lightGrey2;
    padding: md;
    gap: sm;
  }
}
