@value black from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointSmDown, breakpointLgDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
   .container {
       flex: 1 1 auto;
       overflow: auto;
       font-size: 14px;

       @media breakpointSmDown {
           padding: 0;
       }

       @media breakpointLgDown {
           width: 100%;
           flex: none;
       }
   }

    .infiniteScrollContainer {
        /* allows the user to scroll horizontally in the receipt list on smaller screens */

        /* min-width is the container width - scrollbar for accessibility */
        min-width: 896px;

        @media breakpointSmDown {
            min-width: 0;
        }
    }

    .wrapper {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;

        @media breakpointSmDown {
            flex-direction: column;
        }
    }

    .receiptsListWrapper {
        position: relative;
    }

    .arrowWrapper {
        display: none;

        @media breakpointSmDown {
            display: flex;
            justify-content: center;
            flex-direction: column
        }
    }

    .arrowIcon {
        color: black;
    }
}