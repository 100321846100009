@value xs, sm from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value black, grey, midGrey, white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value productCardOverlayTags from "@/modules/styling/zIndex.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";
@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
    .productAttributesContainer {
        display: flex;
        justify-content: space-between;
        margin: sm sm 0;
    }

    .sizeAttributeText {
        color: grey;
    }

    .boostedTag {
        position: absolute;
        top: sm;
        right: sm;
        z-index: productCardOverlayTags;
        color: black;
        background: white;
        padding: 0 xs 1px;
        border-radius: borderRadiusMedium;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);

        @media breakpointMdUp {
            position: relative;
            top: unset;
            right: unset;
            background: none;
            padding: 0;
            border-radius: 0;
            box-shadow: none;

            /* caption1 styles copied from web-ui-kit */
            font-size: 12px;
            line-height: normal;
            color: midGrey;
        }
    }

    .gradientOverlay {
        height: 100%;
        background: linear-gradient(
            1.26deg,
            rgba(0, 0, 0, 0.3) -9.48%,
            rgba(0, 0, 0, 0) 95.88%
        );
    }

    .translucentHoverOverlay {
        height: 100%;
        background: black;
        opacity: 0.3;
    }

    .likeButton {
        position: absolute;
        bottom: xs;
        right: sm;
        z-index: productCardOverlayTags;

        @media breakpointMdUp {
            bottom: sm;
        }
    }

    .likeButtonUnfilled {
        color: white;
    }

    .likedButtonFilled {
        animation: heartbeat 0.5s linear;
        color: white;
    }
}