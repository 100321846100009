@value sm, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .container {
    display: grid;
    grid-template-columns: 120px 1fr;
    gap: lg;
  }

  .wrapper {
    flex: 1;
    min-width: 0;
  }

  .placeholderWrapper {
    margin-bottom: sm;
  }
}
