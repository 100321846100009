@value black, white, governorBay from "@depop/web-ui-kit/theme/default/colors.module.css";
@value xs, sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointXsDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";

@layer components {
  .featureBanner {
    position: relative;
    display: flex;
    height: 124px;
    justify-content: space-between;
    margin: md 0;
    background-color: governorBay;
    border-radius: 8px;
    overflow: hidden;
  }

  .featureBannerText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: md;

    @media breakpointXsDown {
      padding: xs;
    }
  }

  .text {
    color: white;
  }

  .betaTag {
    color: black;
    position: absolute;
    right: sm;
    top: sm;
    border-radius: 12px;
    background-color: white;
    padding: xs;
    line-height: 1rem;
  }
}
