@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value grey from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .attribute {
    display: inline-block;
    color: grey;
  }

  .linkAttribute {
    text-decoration: underline;
  }

  .attributesContainer {
    margin: 0 0 md;

    & .attribute {
      &::after {
        content: ' • ';
        white-space: pre;
      }

      &:last-child::after {
        content: none;
      }

      &:is(a) {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
