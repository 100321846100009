@value blue, black from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointSmUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .wrapper {
    display: inline-flex;
    width: 80%;
    text-align: center;
    align-items: center;

    @media breakpointSmUp {
      width: auto;
    }
  }

  .icon {
    margin-right: sm;
  }

  .buyerProtectionText {
    text-align: left;
    color: black;
  }

  .buyerProtectionLink {
    text-decoration: none;
    color: blue;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }
  }
}
