@value sm, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value a11yYellow, black, white, gallery, black_r, black_g, black_b from "@depop/web-ui-kit/theme/default/colors.module.css";
@value meganavHoverBackdrop from "@/modules/styling/zIndex.module.css";
@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .menuList {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: start;
  }

  .hoverContainer {
    display: none;
    position: absolute;
    left: calc(md * -1);
    right: calc(md * -1);
    padding: 0 md lg;
    top: 53px;
    background: white;
    border-top: 1px solid gallery;

    &::after {
      content: '';
      display: none;
      position: absolute;
      background: rgba(black_r, black_g, black_b, 0.3);
      height: 100vh;
      right: 0;
      left: 0;
      z-index: meganavHoverBackdrop;
      pointer-events: none;
    }
  }

  .hoverContainerActive {
    display: flex;

    &::after {
      display: block;
    }
  }

  .container {
    display: block;
    margin: 0 auto;
    padding: 0 md;
    border-bottom: 1px solid gallery;
  
    @media breakpointMdDown {
      display: none;
    }
  }

  .navCategoryListWide {
    width: 66.6%;
  }

  .navCategoryListNarrow {
    width: 33.3%;
  }
}

@layer overrides {
  .menuListItemButton {
    background: white;
    padding: md;
    border: 0;
    color: var(--textColor);
    cursor: pointer;
  
    &:hover,&:focus-within {
      color: white;
      background: var(--hoverBg, black);
    }
  
    &:focus {
      box-shadow: inset 0 0 0 4px a11yYellow;

      /* Visible in Windows high-contrast themes: https://stackoverflow.com/a/52616313 */
      outline: 4px dotted transparent;
    }
  }

  .menuListItem {
    &:hover,
    &:focus-within {
      .menuListItemButton {
        color: white;
        background: var(--hoverBg, black);
      }
    }
  }
}