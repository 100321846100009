@value md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value a11yYellow, black, lightGrey1 from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .categoryListContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding-right: xl;
    justify-content: space-between;
  }

  .categoryListItemsContainer__largeList {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(6, auto);
    grid-auto-columns: 1fr;
  }

  .categoryListItemsContainer__smallList {
    composes: categoryListItemsContainer__largeList;
    grid-template-rows: repeat(8, auto);
  }

  .categoryListitem {
    list-style: none;
    border-bottom: 1px solid lightGrey1;
  }

  .categoryListLink {
    display: block;
    text-decoration: none;
    padding: md;

    &:hover {
      background-color: lightGrey1;
    }

    &:focus {
      box-shadow: inset 0 0 0 4px a11yYellow;

      /* Visible in Windows high-contrast themes: https://stackoverflow.com/a/52616313 */
      outline: 4px dotted transparent;
    }
  }
}

@layer overrides {
  .categoryListTitle {
    padding: lg md;
  }

  .categoryListItemText {
    color: black;
  }

  .categoryListFooter {
    text-decoration: none;

    &:hover {
      background-color: lightGrey1;
    }
  }
}
