@value lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .marginTop {
    margin-top: xl;

    @media breakpointSmDown {
      margin-top: lg;
    }
  }
}
