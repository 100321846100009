@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .offerTileMenuWrapper {
    position: absolute;
    margin: 0;
    right: md;
  }

  .offerTileMenuButton {
    background-color: transparent;
    border: none;
    outline: none;
  }
}
