@value breakpointSmDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value COLUMN_WIDTH_SMALL from "@/modules/receipts/styles.module.css";

@layer components {
    .wrapper {
       display: flex;
       flex: 0 0 COLUMN_WIDTH_SMALL;

        @media breakpointSmDown {
            flex: 1;
        }
    }

    .wrapper p {
        @media breakpointSmDown {
            text-transform: uppercase;
            font-size: 12px;
        }
    }

    .soldOnText {
        display: none;

        @media breakpointSmDown {
            display: initial;
        }
    }
}