@value sm, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value blue, black, red from "@depop/web-ui-kit/theme/default/colors.module.css";
@value HEADER_HEIGHT_PX from "@/modules/styling/global.module.css";
@value breakpointMdUp, breakpointSmUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value DRAWER_TITLE_HEIGHT: 92px;

@layer components {
  .container {
    width: 100%;
    height: calc(100vh - DRAWER_TITLE_HEIGHT);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    @media breakpointMdUp {
      width: 400px;
      overflow: unset;
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    padding: 0 lg;
    margin-bottom: lg;

    @media breakpointSmUp {
      flex: 1;
      margin: 0;
      overflow-y: scroll;
    }
  }

  .helpLink {
    display: inline-block;
    color: blue;
    font-weight: bold;
    text-decoration: none;
    font-size: 0.75rem;
  }

  .description {
    color: black;
  }

  .actions {
    padding: 0 lg lg;
    text-align: center;
  }

  .confirmAndBoostButton {
    margin: md 0;
    width: 100%;
  }

  .errorMessage {
    color: red;
    margin-bottom: sm;
  }

  .termsAndConditions {
    color: blue;
    margin-bottom: md;
  }
}
