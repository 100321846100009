@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value lightGrey2 from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .container {
    display: flex;
    flex-direction: column;
    gap: md;

    @media breakpointMdUp {
      gap: lg;
    }
  }
}
