@value sm, md, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .pillsContainer {
    display: flex;
    overflow-x: auto;
    margin-left: calc(-1 * md);
    padding: md;
    width: calc(100% + xl);
    gap: sm;

    & button {
      padding: 12px;

      &:first-of-type {
        margin-left: 0;
      }
    }

    @media breakpointMdUp {
      width: unset;
      margin-left: 0;
      padding-right: 0;
    }
  }

  .link {
    white-space: nowrap; /* Prevent text from wrapping to the next line */

    &:first-of-type {
      margin-left: md;
    }
  }
}
