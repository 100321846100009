@value black, white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value fontFamilySplash from "@depop/web-ui-kit/theme/default/fonts.module.css";
@value breakpointSmDown, breakpointSmUp, breakpointMdDown, breakpointLgDown, breakpointXlDown, mdMinPx, lgMaxPx from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value xs, sm, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .container {
    display: flex;
    max-height: 485px;
    overflow: hidden;
    margin-bottom: lg;

    @media breakpointSmUp {
      margin-bottom: xl;
    }

    @media breakpointMdDown {
      flex-direction: column;
      max-height: none;
    }
  }

  .title {
    font-size: 48px;
    color: white;
    text-shadow: rgba(0, 0, 0, 0.4) 0 0 10px;
    font-family: fontFamilySplash;

    @media (min-width: mdMinPx) and (max-width: lgMaxPx) {
      font-size: 32px;
    }

    @media breakpointSmDown {
      font-size: 24px;
    }
  }

  .link {
    position: relative;
    width: 100%;
  }

  .image {
    display: block;
    width: 100%;
  }

  .titleButtonContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    bottom: 25%;
    gap: sm;
    left: 50%;
    transform: translateX(-50%);
  }

  .button {
    background-color: white;
    color: black;
    border-color: white;
    padding: 10px 14px;
    border-radius: xs;

    &:hover {
      background-color: white;
      border-color: white;
    }
  }
}
