@value xs, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmDown, breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .nav {
    display: flex;
    height: xl;

    @media breakpointSmDown {
      margin: 0 0 0 auto;
    }
  }

  .navigationButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: xs;
    margin-left: -2px;

    @media breakpointMdDown {
      margin-right: -4px;
    }
  }

  .navigationButtons--loggedIn {
    @media breakpointMdDown {
      margin-right: -2px;
    }
  }
}
