@value black, white, lightGrey1, red from "@depop/web-ui-kit/theme/default/colors.module.css";
@value mobileNavMenuWrapperLayer from "@/modules/styling/zIndex.module.css";
@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .container {
    padding-bottom: 124px;
  }

  .header {
    padding: md;
  }

  .headerContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .closeButton {
    display: flex;
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
  }

  .authButtons {
    display: flex;
    gap: sm;
    margin-top: md;
    flex-direction: column;

    & a {
      text-decoration: none;
      display: block;
      width: 100%;
    }
  }

  .primaryNav {
    list-style: none;
    border-top: none;

    &--showBorderTop {
      border-top: 1px solid black;
    }

    & li {
      border-bottom: 1px solid lightGrey1;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .navListItem {
    padding: md;

    a {
      display: inline-block;
      text-decoration: none;
    }
  }

  .navListItemButton {
    padding: 0;
    background: transparent;
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: black;
  }

  .authenticatedNav {
    border-bottom: 1px solid black;
    border-top: 1px solid black;

    & li {
      border-bottom: 1px solid lightGrey1;

      &:last-child {
        border-bottom: none;
      }
    }

    & a:first-child {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .offerAlertContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .offerAlertNotificationContainer {
    position: relative;
    display: inline-block;
    right: 0;
    top: 0;
    margin-right: sm;
    background-color: red;
    width: 8px;
    height: 8px;
    border-radius: 100%;
  }

  .offerAlertText {
    color: red;
    font-size: 12px;
  }

  .secondaryNavWrapper {
    padding: md;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }

  .getTheAppLink {
    display: inline-block;
    text-decoration: none;
    margin-top: md;
    text-transform: lowercase;
    color: black;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .footer {
    padding: md;
  }

  .socialLinks {
    display: grid;
    grid-template-columns: repeat(4, 24px);
    gap: md;
    margin-bottom: md;
  }

  .selectWrapper {
    display: flex;
    flex-direction: column;
    gap: sm;
  }

  .mobileMenuWrapper {
    position: fixed;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    top: 0;
    left: 0;
    transform: translate3d(-100%, 0, 0);
    transition: transform 250ms ease-in-out;
    bottom: 0;
    z-index: mobileNavMenuWrapperLayer;
    width: 344px;
    overflow: auto;
    background: white;
    color: black;

    &--slideIn {
      visibility: visible;
      transform: translate3d(0, 0, 0);
      box-shadow: 8px 0 48px rgba(0, 0, 0, 0.2);
    }

    &--slideOut {
      transform: translate3d(-100%, 0, 0);
    }

    &--hidden {
      visibility: hidden;
    }
  }

  .logoutButton {
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    margin-top: md;
  }

  .socialLink {
    position: relative;
  }
}

@layer overrides {
  .depopLogo {
    width: 90px;
    height: 23px;
  }

  .navListItemLabel {
    color: var(--textColor);
  }
}
