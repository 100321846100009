@value white, lightGrey1, black from "@depop/web-ui-kit/theme/default/colors.module.css";
@value mobileNavSubMenuWrapperLayer from "@/modules/styling/zIndex.module.css";
@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value fontFamilyBody from "@depop/web-ui-kit/theme/default/fonts.module.css";

@layer components {
  .container {
    position: fixed;
    top: 0;
    left: -100%;
    height: 100vh;
    overflow-y: auto;

    /* Needs to be large enough to not be blocked by the url bar on actual mobile devices */
    padding-bottom: 86px;
    width: 344px;
    background: white;
    transition: all 0.4s ease 0s;
    z-index: mobileNavSubMenuWrapperLayer;
    overscroll-behavior: contain;
  }

  .container--open {
    composes: container;
    left: 0;
  }

  .header {
    text-align: center;
    padding: md;
    width: 100%;
  }

  .actionButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: md;
  }

  .actionButton {
    display: flex;
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
  }

  .list {
    list-style: none;
    border-bottom: 1px solid black;

    & li:first-child, li:last-child {
      border-bottom: none;
    }
  }

  .baseListItem {
    padding: md;
    border-bottom: 1px solid lightGrey1;
  }

  .popularTilesContainer {
    padding: md;
  }
}

@layer overrides {
  .anchor {
    width: 100%;
    font-family: fontFamilyBody;
    color: var(--textColor, black);
    font-weight: normal;
    font-size: 100%;
  }

  .anchor--active {
    composes: anchor;
    font-weight: bold;
  }
}
