@value breakpointXsDown, breakpointSmUp, breakpointSmDown, breakpointMdUp, breakpointLgUp, breakpointXlUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value COLUMN_WIDTH_SMALL, COLUMN_WIDTH_MEDIUM, COLUMN_WIDTH_LARGE from "@/modules/receipts/styles.module.css";

@layer components {
    .wrapper {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: unset;
        position: relative;

        @media breakpointXsDown {
            flex: none;
        }

        @media breakpointSmUp {
            flex: 1;
        }

        @media breakpointMdUp {
            width: COLUMN_WIDTH_MEDIUM;
        }

        @media breakpointXlUp {
            width: COLUMN_WIDTH_LARGE;
        }
    }

    .location {
        display: flex;
        flex-direction: column;

        @media breakpointSmDown {
            display: none;
        }
    }

    .locationDetails {
        display: inline-block;
        vertical-align: bottom;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: COLUMN_WIDTH_SMALL;

        @media breakpointSmUp {
            width: COLUMN_WIDTH_MEDIUM
        }

        @media breakpointLgUp {
            width: unset;
        }
    }
}