@value md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .container {
    margin-top: xl;
    min-width: 33.3%;
    max-width: 33.3%;
    display: none;
  
    @media breakpointLgUp {
      display: block;
      flex-direction: row;
      margin-top: 0;
      padding: lg md;
    }    
  }

  .featuredContainer {
    display: grid;
    grid-template:
      'a' auto
      'b' auto;
    gap: md;
    justify-content: center;
  
    @media breakpointLgUp {
      grid-template:
        'a b' auto
        'c d' auto;
      grid-template-columns: 1fr 1fr;
    }
  
    a {
      display: flex;
      position: relative;
    }
  }

  .heading {
    margin-bottom: md;
  }
}