@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value governorBay from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .deleteButton {
    display: inline-block;
    left: 0;
    align-self: start;
    padding: md 0 sm 0;
  }

  .deleteText {
    color: governorBay;
  }

  .spinner {
    color: governorBay;
    margin-top: md;
  }
}
