@value breakpointSmDown, breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
    padding: 0 16px;
  }

  .wrapper--narrow {
    max-width: 992px; /** 960px + 16px padding either side */
  }

  .wrapper--full {
    max-width: none;
    padding: 0 32px;

    @media breakpointSmDown {
      padding: 0 16px;
    }
  }

  .wrapper--gridMaxWidth {
    @media breakpointLgUp {
      padding: 0;
    }
  }
}
