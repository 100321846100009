@value xs from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .price {
    display: inline-block;
  }

  .discountedFullPrice {
    text-decoration: line-through;
  }

  .discountWrapper {
    &p:nth-child(2) {
      padding: 0 xs;
    }
  }
}
