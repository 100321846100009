@value black, blue, lightGrey1, lightGrey2, silverChalice from "@depop/web-ui-kit/theme/default/colors.module.css";
@value md, sm from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .container {
    display: flex;
    flex-direction: column;
    text-align: center;
    border-bottom: 1px solid lightGrey2;
    padding: md;
    gap: sm;
  }

  .depopShippingDetails {
    margin-top: sm;
  }

  .moreInfoLink {
    color: blue;
  }

  .paymentReceived,
  .paymentRefunded {
    margin-bottom: sm;
  }

  .statementText {
    padding: md 0;
    background-color: lightGrey1;
    margin-bottom: md;
  }

  .taxSummary {
    margin-top: sm;
  }

  .table {
    border-collapse: collapse;
    margin-bottom: md;
    width: 100%;
  }

  .row {
    border-bottom: 1px solid silverChalice;

    &:last-child {
      border-bottom: none;

      * {
        font-weight: bold;
      }
    }
  }

  .label {
    text-align: left;
  }

  .labelText,
  .priceText {
    color: black;
  }

  .price {
    text-align: right;
  }

  .label,
  .price {
    padding: sm 0;
  }
}
