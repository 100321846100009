@value breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value productCardHoverOverlay from "@/modules/styling/zIndex.module.css";
@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";
@value a11yYellow from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .productCardRoot {
    position: relative;
  }

  .baseProductImageContainer {
    position: relative;
    border-radius: borderRadiusMedium;
    overflow: hidden;
    aspect-ratio: 1 / 1;

    img {
      width: 100%;
    }

    transition: filter 0.3s ease;
  }

  .productImageContainer__withoutHoverOverlay {
    composes: baseProductImageContainer;

    &:hover {
      filter: brightness(0.7);
    }
  }

  .productImageContainer__withHoverOverlay {
    composes: baseProductImageContainer;
  }

  .overlay {
    position: absolute;
    inset: 0;
  }

  .hoverOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.3s ease opacity;
    z-index: productCardHoverOverlay;

    &:hover,
    &:focus {
      opacity: 1;
    }

    @media (hover: none) {
      display: none;
    }
  }
}
