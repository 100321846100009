@value lightGrey1, lightGrey2, black from "@depop/web-ui-kit/theme/default/colors.module.css";
@value xs, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value fontFamilyBody from "@depop/web-ui-kit/theme/default/fonts.module.css";

@layer components {
  .footerUpperContainer {
    background-color: lightGrey1;
  }

  .footerUpperContent {
    display: flex;
    flex-direction: column;
    padding-bottom: md;
    max-width: 1280px;
    margin: 0 auto;

    @media breakpointMdUp {
      flex-direction: row;
      justify-content: space-between;
      padding: 0 0 md 0;
    }
  }

  .footerColumnMobileContainer {
    padding: 0 md;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media breakpointMdUp {
      display: none;
    }
  }

  .footerColumnDesktopContainer {
    display: none;
    flex-direction: column;
    gap: 48px;
    flex-grow: 2;

    @media breakpointMdUp {
      display: flex;
      flex-direction: row;
    }
  }

  .footerColumn {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .footerColumTitle {
    padding: lg md;
  }

  .footerSocialColumn {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: md;

    @media breakpointMdUp {
      align-items: flex-end;
      justify-content: flex-end;
      padding: 0;
    }
  }

  .footerSocialContainer {
    display: flex;
    gap: lg;
    align-items: center;

    @media breakpointMdUp {
      padding-right: md;
    }
  }

  .socialLink {
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.5;
    }
  }

  .footerLowerContainer {
    background-color: lightGrey2;
  }

  .footerLowerContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1280px;
    margin: 0 auto;

    @media breakpointMdUp {
      justify-content: space-between;
      padding: md 0;
      flex-wrap: wrap;
    }
  }

  .footerLowerDesktopColumn {
    display: none;

    @media breakpointMdUp {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .footerLocalisationContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media breakpointMdUp {
      flex-direction: row;
      width: unset;
    }
  }

  .footerLinkWrapper {
    padding: md;
  }

  .footerLink {
    display: inline-block;
    text-decoration: none;
    color: black;

    &:hover {
      text-decoration: underline;
    }
  }

  .footerCookieButton {
    display: inline-block;
    text-decoration: none;
    color: black;
    padding: md;
    background-color: unset;
    border: none;
    cursor: pointer;
    align-self: flex-start;

    &:hover {
      text-decoration: underline;
    }
  }

  .footerAccordianButton {
    display: flex;
    justify-content: space-between;
    background-color: unset;
    border: none;
    cursor: pointer;
    padding: lg 0;
  }
}

@layer overrides {
  .selectLocation {
    border: none;
    display: flex;

    @media breakpointMdUp {
      width: var(--width);
      margin-right: xs;

      &:hover,
      &:focus-within {
        background-color: lightGrey1;
      }
    }

    > svg {
      z-index: unset;
      cursor: pointer;
      pointer-events: none;
      right: md;
      position: absolute;
      top: 50%;
      margin-top: -sm;
      width: md;
      height: md;
    }

    > select {
      padding: md 0 md md;
      border-radius: md;
      cursor: pointer;
      font-family: fontFamilyBody;

      &:focus-visible {
        outline: none;
      }
    }
  }

  .selectLanguage {
    border: none;
    display: flex;

    @media breakpointMdUp {
      width: var(--width);

      &:hover,
      &:focus-within {
        background-color: lightGrey1;
      }
    }

    > svg {
      z-index: unset;
      cursor: pointer;
      pointer-events: none;
      right: md;
    }

    > select {
      padding: md 0 md md;
      border-radius: md;
      cursor: pointer;
      font-family: fontFamilyBody;

      &:focus-visible {
        outline: none;
      }
    }
  }
}
