@value red from "@depop/web-ui-kit/theme/default/colors.module.css";
@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .indicator {
    background: red;
    width: sm;
    height: sm;
    display: block;
    border-radius: 100%;
    position: absolute;
    top: 0;
    right: -1px;
  }
}
