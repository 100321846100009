@value breakpointSmDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value COLUMN_WIDTH_LARGE from "@/modules/receipts/styles.module.css";

@layer components {
    .wrapper {
        display: flex;
        flex: 0 0 COLUMN_WIDTH_LARGE;
        text-transform: capitalize;

        @media breakpointSmDown {
            display: none;
        }
    }
}