@value breakpointSmDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value midGrey from "@depop/web-ui-kit/theme/default/colors.module.css";
@value COLUMN_WIDTH_LARGE from "@/modules/receipts/styles.module.css";

@layer components {
    .wrapper {
        display: flex;
        flex: 0 0 COLUMN_WIDTH_LARGE;

        @media breakpointSmDown {
            flex: 1;
        }
    }

    .wrapper p {
        @media breakpointSmDown {
            font-size: 12px;
            color: midGrey;
        }
    }
}