@value md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value lightGrey2 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .divider {
    margin: md 0;
    background: lightGrey2;
    border: none;
    height: 1px;

    @media breakpointMdUp {
      margin: lg 0;
    }
  }
}
