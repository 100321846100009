@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .heading {
    margin-right: sm;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .status {
    margin-top: 10px;
  }
}
