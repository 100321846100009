@value xs, sm  from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value lightBlue, governorBay, black from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .container {
    background-color: lightBlue;

    /* Padding approved with designers - 23/08/23 */
    padding: xs 11px xs sm;
    border-radius: 50px;

    /* Color here applies to icon embedded */
    color: governorBay;
    display: inline-flex;
    align-items: center;
  }

  .nudgeText {
    margin-left: sm;
    color: black;
  }
}
