@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value black, midGrey from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .wrapper {
    display: none;

    @media breakpointMdDown {
      display: block;
      min-width: 0;
    }
  }

  .triggerButton {
    background: transparent;
    border: 0;
    padding: 0;
    font-size: 0;
    cursor: pointer;

    /* 16px - 4px to account for icon padding */
    margin-right: 12px;
  }
}

@layer overrides {
  .triggerIcon {
    color: black;
    width: 30px;
    height: 30px;
    cursor: pointer;

    &:hover {
      color: midGrey;
    }
  }
}
