@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .heading {
    margin-bottom: sm;
  }

  .headingContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: sm;
  }

  .sellingItemImage {
    min-height: auto;
    min-width: auto;
  }

  .soldOutImageContainer {
    position: relative;
  }
}
