@value xs, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value lightGrey1, grey, white from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer component {
  .container {
    display: grid;
    grid-template-columns: minmax(368px, 40%) 60%;
    min-height: 224px;
    border-radius: xs;
    overflow: hidden;
    height: 240px;

    @media breakpointMdDown {
      display: flex;
      flex-direction: column-reverse;
      height: 100%;
    }
  }

  .contentWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: grey;
    padding: lg lg lg 48px;

    @media breakpointMdDown {
      flex: 1;
      text-align: center;
      justify-content: flex-start;
      padding: md;
    }
  }

  .heading {
    margin-bottom: xs;
    color: white;

    @media breakpointMdDown {
      font-size: 18px;
    }
  }

  .bodyParagraph {
    margin-bottom: lg;
    color: white;

    a {
      white-space: nowrap;
      color: white;
      text-decoration: underline;

      &:hover {
        opacity: 0.5;
      }
    }

    @media breakpointMdDown {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: md;
    }
  }

  .ctaWrapper {
    a {
      display: inline-flex;
    }
  }

  .bannerImage {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .bannerButton {
    border-width: 0;
  }
}
