@value black, green, grey, red, white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value xs, sm, md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .offerTileContainer {
    background-color: white;
    padding: md;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }

  .offerTileRow {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
  }

  .offerTileTextContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    order: 1;
  }

  .offerTileTitle {
    color: grey;
    flex-grow: 1;
    font-size: 12px;
    margin-bottom: xs;
  }

  .purchaseableOfferTitle {
    color: green;
  }

  .rejectedOfferTitle {
    color: red;
  }

  .offerTileText {
    color: grey;
    font-size: 10px;
  }

  .purchaseableOfferTileText {
    color: green;
  }

  .offerTilePriceContainer {
    display: flex;
    align-items: center;
  }

  .offerTilePrice {
    color: black;
    margin-right: sm;
    font-size: 14px;
  }

  .offerTileOriginalPrice {
    color: grey;
    text-decoration: line-through;
  }

  .offerTileLinkContainer {
    --mobileImage: 84px;

    height: var(--mobileImage);
    width: var(--mobileImage);
    order: 2;
  }

  .offerTileButtonsContainer {
    flex-grow: 1;
    display: flex;
    margin-top: md;
    gap: xs;
    order: 3;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
  }

  .offerTileButtonsContainerInline {
    gap: sm;
    flex-direction: row;
    justify-content: flex-start;
  }

  .offerTileBreakpointStyles {
    --desktopImage: 120px;

    @media breakpointMdUp {
      .offerTileContainer {
        padding: lg;
      }

      .offerTileTextContainer {
        order: 2;
        margin-left: lg;
      }

      .offerTileTitle {
        font-size: 16px;
      }

      .offerTilePriceContainer {
        margin-bottom: xs;
      }

      .offerTileLinkContainer {
        height: var(--desktopImage);
        width: var(--desktopImage);
        order: 1;
        margin-top: 0;
      }

      .offerTileButtonsContainer {
        align-items: flex-end;
        max-width: 300px;
        margin-top: 0;
      }
    }
  }
}
