@value md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
    .imageWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: lg;
        position: relative;

        @media breakpointSmDown {
            flex-direction: row;
            flex: none;
            padding: 0;
            margin-right: md;
        }
    }
}