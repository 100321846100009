@value white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value xs, sm from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .productAttributesContainer {
    margin: sm xs 0;
  }

  .videoIcon {
    position: absolute;
    bottom: sm;
    left: sm;
    color: white;
  }
}
