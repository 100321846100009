@value productDescriptionShowMoreButton, productDescriptionShowMoreButtonBg from "@/modules/styling/zIndex.module.css";
@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value sm, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value maxLinesDesktop: 7;
@value maxLinesMobile: 10;
@value textLightHeight: 1.5;
@value textFontSize: 0.875rem;
@value lineHeight: textLightHeight * textFontSize;

@layer components {
  .container {
    position: relative;

  }

  .container--collapsed {
    margin-bottom: sm;

    @media breakpointMdUp {
      margin-bottom: md;
    }
  }

  .container--expanded {
    margin-bottom: lg;

  }

  .textWrapper {
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: pre-line;
  }

  .textWrapper--collapsed {
    display: -webkit-box;
    overflow: hidden;
    max-height: calc(maxLinesMobile * lineHeight);
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;

    @media breakpointMdUp {
      max-height: calc(maxLinesDesktop * lineHeight);
      -webkit-line-clamp: 7;
    }
  }

  .showMoreContainer {
    display: flex;
    justify-content: flex-end;
  }

  .showMoreButton {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    bottom: 0;
    right: 0;
    z-index: productDescriptionShowMoreButton;

    &::after {
      position: absolute;
      z-index: productDescriptionShowMoreButtonBg;
      right: 0;
      pointer-events: none;
      height: 100%;
      width: 150%;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255) 30%);
    }
  }

  .showMoreButton--expanded {
    position: relative;

    &::after {
      content: none;
    }
  }

  .showMoreButton--collapsed {
    position: absolute;

    &::after {
      content: "";
    }
  }
}

@layer overrides {
  .link {
    font-weight: bold;
    color: inherit;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}