@value sm, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointLgDown, breakpointMdDown, breakpointSmUp, breakpointMdUp, breakpointSmDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value searchBarContainerLayer, searchBarInputActionLayer from "@/modules/styling/zIndex.module.css";
@value grey, black, doveGray, boulder, boulder_h, boulder_s, boulder_l, white, black, governorBay, lightGrey1 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value fontFamilyBody from "@depop/web-ui-kit/theme/default/fonts.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";

@layer components {
  .searchBarContainer {
    display: flex;
    flex-direction: column;
    margin-left: lg;
    position: relative;
    z-index: searchBarContainerLayer;

    @media breakpointMdDown {
      display: block;
      margin-left: 0;
    }
  }

  .searchBarContainer--desktopOnly {
    @media breakpointMdDown {
      display: none;
    }
  }

  .wrapper {
    position: relative;
    margin: auto;
    display: flex;
    width: calc(42vw);
    max-width: 704px;

    @media breakpointMdDown {
      width: calc(100vw - 32px);
    }
  }

  .wrapper--shouldShrink {
    @media breakpointLgDown {
      width: calc(34vw);
    }
  }

  .searchBarIcon {
    position: absolute;
    vertical-align: middle;
    display: block;
    height: 100%;
    left: md;
    z-index: searchBarInputActionLayer;
    color: doveGray;

    @media breakpointMdDown {
      left: sm;
    }
  }

  .searchBarIcon--focused {
    color: black;

    @media breakpointMdDown {
      color: grey;
    }
  }

  .input {
    height: 40px;
    border-radius: borderRadiusMedium;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border: 2px solid black;
    width: 100%;
    font-family: fontFamilyBody;
    padding: 8px 8px 0;
    box-shadow: none;
    appearance: none;

    &:focus {
      outline: none;
    }

    ::placeholder {
      font-size: 14px;
      color: grey;
    }

    /* Needed to override a media query in Input */
    @media screen and (min-width: 0) {
      padding: 0 8px 0 40px;
    }

    @media breakpointSmDown {
      padding: 0 8px 0 40px;
    }

    @media breakpointMdUp {
      border-radius: 30px;
      background: lightGrey1;
    }
  }

  .commonButtonStyles {
    position: absolute;
    display: flex;
    align-items: center;

    :disabled {
      background-color: transparent;
      border-color: transparent;
    }

    &:hover {
      cursor: pointer;
    }

    &:hover,
    &:focus {
      outline: 0;
      box-shadow: none;

      :disabled {
        background-color: transparent;
        border-color: transparent;
      }
    }
  }

  .clearButton {
    composes: commonButtonStyles;
    top: 10px;
    right: 48px;
    height: 20px;
    justify-content: center;
    padding: 0 0 1px 0;
    min-width: 48px;
    background-color: boulder;
    border: none;
    color: white;
    font-size: 9px;
    text-transform: uppercase;
    border-radius: 28px;
    font-weight: normal;
    z-index: searchBarInputActionLayer;
    font-family: fontFamilyBody;
    transition: all 0.2s ease;

    &:hover,
    &:focus {
      background-color: hsl(boulder_h boulder_s calc(boulder_l + 20%));
      color: white;
      border: none;
    }

    @media breakpointMdDown {
      top: sm;
      height: lg;
      font-family: inherit;
      transition: none;
    }
  }

  .submitButton {
    composes: commonButtonStyles;
    top: 0;
    right: 0;
    height: 40px;
    padding: 0 8px;
    width: 40px;
    background-color: transparent;
    border-color: transparent;
    color: black;
    z-index: searchBarInputActionLayer;
    border-radius: 0 30px 30px 0;
    transition: all 0.2s ease;

    &:hover,
    &:focus {
      background-color: governorBay;
      color: white;
      border-color: transparent;
    }

    @media breakpointMdDown {
      border-radius: 0;
      transition: none;
    }
  }

  .searchBarButtons {
    display: none;

    @media breakpointSmUp {
      display: block;
    }
  }
}
