@value fontFamilyBody from "@depop/web-ui-kit/theme/default/fonts.module.css";
@value black, lightGrey2 from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .buttonMinimal {
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-family: fontFamilyBody;
    font-weight: normal;
    background-color: transparent;
    color: black;
    padding: 0;
    border: none;
    line-height: 1.25;
  }

  .buttonMinimal:hover {
    background: transparent;
    border: none;
    text-decoration: underline;
  }

  .buttonMinimal[disabled] {
    background: transparent;
    border: none;
    cursor: not-allowed;
    color: lightGrey2;
  }
}
