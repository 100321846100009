@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value midGrey from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .container {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 120px 0;

    p {
      margin-bottom: sm;
    }

    a {
      margin-top: md;
    }

    @media breakpointMdDown {
      margin: md 0;
    }
  }

  .icon {
    margin-bottom: sm;
    color: midGrey;
  }
}
