@value lightGrey2, midGrey, white, midGrey, lightGrey1, yellow from "@depop/web-ui-kit/theme/default/colors.module.css";
@value autoSuggestBaseResults from "@/modules/styling/zIndex.module.css";

@layer components {
    .wrapper {
        position: relative;
        width: 100%;
    }

    .resultsContainer {
        max-height: var(--maxHeight);
        overflow-y: scroll;
        position: absolute;
        left: var(--left);
        right: var(--right);
        top: var(--top);
        border-radius: 8px;
        border: 1px solid lightGrey2;
        background: white;
        box-shadow: 0 2px 8px 0 midGrey;
        z-index: autoSuggestBaseResults;
    }

    .resultItems {
        list-style: none;
    }

    .resultOptionContent {
        padding: 12px 0;
        margin: 0 16px;
        border-bottom: 1px solid lightGrey1;
        font-size: 12px;
        color: midGrey;
        line-height: 1.5;

        &:focus {
            border-color: transparent;
        }
    }

    .resultOption {
        cursor: var(--cursor);
        opacity: var(--opacity);

        &:hover {
            background: lightGrey1;
        }

        &:focus {
            box-shadow: inset 0 0 0 4px yellow;

            /* Visible in Windows high-contrast themes: https://stackoverflow.com/a/52616313 */
            outline: transparent dotted 4px;
        }
    }

    .resultOptionTextWrapper {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        margin-bottom: 4px;
    }

    .resultOptionText {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .resultOptionCaption {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .resultOptionIconWrapper {
        width: 21px;
        height: 21px;
        margin-left: 8px;
    }
}