@value black, lightGrey1, white, midGrey, a11yYellow from "@depop/web-ui-kit/theme/default/colors.module.css";
@value sm, md, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";
@value fontFamilyBody from "@depop/web-ui-kit/theme/default/fonts.module.css";
@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .scrollableContainer {
    overflow-x: scroll;
    overflow-y: hidden;
    margin: 0 -16px;
    padding: 0 md;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .pillList {
    list-style: none;
    display: flex;
    gap: sm;
    flex-wrap: wrap;
    max-height: 106px; /* Hide any pills on a third row */
    width: calc(200% + xl); /* Add 32px to account for the negative margin on the scrollableContainer */

    @media breakpointMdUp {
      max-height: 114px; /* Hide any pills on a third row */
      width: 100%;
      gap: md;
    }
  }

  .pill {
    appearance: none;
    font-size: 0.875rem;
    background: white;
    border: 1px solid midGrey;
    margin: 0;
    line-height: 1;
    border-radius: borderRadiusMedium;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    padding: md xl;
    color: black;
    font-family: fontFamilyBody;
    transition: background 0.2s ease, box-shadow 0.2s ease;

    &:hover {
      background: rgba(243, 243, 243, 0.376);
    }

    &:focus {
      box-shadow: inset 0 0 0 4px a11yYellow;

      /* Visible in Windows high-contrast themes: https://stackoverflow.com/a/52616313 */
      outline: 4px dotted transparent;
    }
  }
}
