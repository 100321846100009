@value xs, sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .counterOfferModalContainer {
    padding: sm md md;
  }

  .counterOfferModalHeadingContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    left: 0;
    right: 0;
    margin-top: xs;
  }
}
