@value white, blue, black, lightGrey1, lightGrey2, midGrey from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .actionMenuItemStyle {
    a, button {
      width: 100%;
      color: black;
      font-size: 14px;
    }    
  }

  .actionMenuItemStyleMinimal {
    a, button {
      text-align: left;
      padding: 8px 16px 8px 8px;
      margin: 0;
      border-bottom: 1px solid lightGrey1;
    
      &:hover,
      &:focus,
      &:active {
        background: lightGrey1;
        text-decoration: none;
        outline: none;
        border-bottom: 1px solid lightGrey1;
      }
    
      &:last-of-type {
        border-bottom: 0;
      }
    }
  }
  
  .actionMenuItemStyleCenter {
    a, button {
      text-align: center;
      padding: 8px 16px;
    }
  }
  
  .actionMenuItemStyleBlock {
    a, button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
      padding: 16px;
      border-bottom: 1px solid lightGrey1;
    
      & a {
        color: black;
        text-decoration: none;
      }
    
      &:visited {
        color: black;
      }
    
      &:hover,
      &:hover a,
      &:focus,
      &:focus a {
        background-color: blue;
        color: white;
        outline: none;
        text-decoration: none;
      }
    
      &:last-child {
        border-bottom: none;
      }
    }
  }

  .wrapper {
    position: fixed;
    box-shadow: 0 0 5px 0 lightGrey2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: white;
    border-radius: 2px;
    overflow: hidden;
    z-index: 1;
    min-width: 180px;
    max-width: 400px;
  }
  
  .mobileOverlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    inset: 0 0 0 0;
    background: midGrey;
    z-index: 3;
  }
  
  .mobileWrapper {
    display: flex;
    flex-direction: column;
    min-width: 180px;
    background: white;
    z-index: 1;
    border-radius: 8px;
  } 
}
