@value xs, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
    .container {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: md;
        bottom: md;

        @media breakpointSmDown {
            display: none;
        }
    }

    .messageButtonContainer {
        width: 24px;
        display: flex;
        justify-content: center;
        margin-left: xs;
    }
}

@layer overrides {
    .messageButtonContainer svg {
        width: 18px;
        height: 18px;
    }
}