@value xs, sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdUp, breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value black, white from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .sellLinkWrapper {
    position: relative;
    display: none;

    span {
      right: xs;
      top: sm;
    }

    @media breakpointMdUp {
      display: flex;
    }
  }
}
