@value black, white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer overrides {
  .textButton--login {
    background-color: white;
    color: black;
    border: none;
    padding-left: sm;
    padding-right: sm;

    @media breakpointLgUp {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .textButton--signup {
    padding-left: sm;
    padding-right: sm;

    @media breakpointLgUp {
      background-color: white;
      color: black;
      padding-left: md;
      padding-right: md;
    }
  }
}
