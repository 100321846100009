@value sm, md, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmUp, breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
    .emptyListTitleContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .emptyListTitle {
        font-size: 24px;
    }

    .emptyListBodyText {
        margin-top: sm;
    }

    .emptyListSubHeading {
        margin-top: xl;
    }

    .emptyProductList {
        display: grid;
        gap: sm;
        grid-template-columns: repeat(2, 1fr);
        margin-top: xl;

        @media breakpointSmUp {
            grid-template-columns: repeat(3, 1fr);
        }

        @media breakpointMdUp {
            grid-template-columns: repeat(6, 1fr);
            gap: md;
        }
    }

    .listItem {
        list-style-type: none;
    }

    .loaderWrapper {
        padding-top: xl;
        padding-bottom: xl;
      }
}