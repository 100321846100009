@value md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value red from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .title {
    margin-bottom: lg;
  }

  .showMoreButton {
    margin-bottom: md;
    font-size: 14px;
    padding: 0;
  }

  .invalidShippingAddress {
    color: red;
    margin: md 0;
  }
}
