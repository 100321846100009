@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .container {
    text-align: center;
    margin: 120px 0;
  }

  .title {
    margin-bottom: sm;
  }
}