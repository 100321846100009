@value sm, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value black from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .navTitle {
    margin-bottom: sm;
  }

  .navSecondaryList {
    column-count: 2;
    column-gap: lg;
    margin-top: md;
    list-style: none;
    padding: 0;
  }

  .navListItem {
    cursor: pointer;
    margin-bottom: md;
    vertical-align: top;
    width: 100%;

    &:hover {
      text-decoration: underline;
    }
  }

  .navLink {
    color: black;
    text-decoration: none;
  }

  .cookiesButton {
    color: black;
    text-decoration: none;
    font-family: inherit;
    font-size: inherit;
    background: transparent;
    padding: 0;
    border: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
