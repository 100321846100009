@value lightGrey2 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value lg, md, sm, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .container {
    padding: sm xl;
    text-align: center;
    border-bottom: 1px solid lightGrey2;
  }

  .content {
    display: flex;
  }

  .image {
    height: fit-content;
  }

  .productDetailContainer {
    display: block;
    margin: md 0;
  }

  .shippingContainer {
    display: block;
    margin: lg 0 sm 0;
    width: 100%;
  }

  .summary {
    display: flex;
    flex-wrap: wrap;
    gap: 0 md;
    margin-top: md;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    margin-left: md;
    text-align: left;
  }

  .bold {
    * {
      font-weight: bold;
    }
  }
}
