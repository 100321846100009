@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmUp, breakpointLgUp, breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value lightGrey1, mineShaft, white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value fontFamilyBody from "@depop/web-ui-kit/theme/default/fonts.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";

@layer components {
  .actionWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: md;
    margin-bottom: 0;

    @media breakpointLgUp {
      align-items: center;
      margin-bottom: 0;
    }
  }

  .filterButton {
    padding: sm md;
    display: none;
    align-items: center;
    justify-content: space-between;
    column-gap: sm;
    background-color: lightGrey1;
    border: 1px solid #acacac;
    border-radius: borderRadiusMedium;
    font-family: fontFamilyBody;
    cursor: pointer;

    @media breakpointLgUp {
      display: flex;
    }
  }

  .mobileFilterButton {
    display: flex;
    padding: sm 10px;
    background-color: white;

    @media breakpointLgUp {
      display: none;
    }
  }

  .activeFilterCount {
    border-radius: 50%;
    background-color: mineShaft;
    height: 20px;
    width: 20px;
    color: white;
  }

  .notificationWrapper {
    position: relative;
  }

  .containerGrow {
    display: flex;
    align-content: space-between;
  }

  .filterWrapper {
    display: none;
    padding-top: md;
    background-color: white;

    @media breakpointMdUp {
      display: block;
    }

    @media breakpointLgUp {
      padding-top: md;
    }
  }

  .activeFilterDesktopContainer {
    padding-top: md;
    display: none;

    @media breakpointLgUp {
      display: inline-block;
    }
  }

  .activeFilterMobileContainer {
    display: flex;

    @media breakpointLgUp {
      display: none;
    }
  }

  .desktopSellingFilters {
    display: none;

    @media breakpointSmUp {
      display: block;
    }
  }

  .mobileSellingFilters {
    display: block;

    @media breakpointSmUp {
      display: none;
    }
  }
}
