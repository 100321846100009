@value black from "@depop/web-ui-kit/theme/default/colors.module.css";
@value lg from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .container {
    display: flex;
    align-items: center;
    color: black;

    p,
    span {
      color: inherit;
    }
  }

  .shippingIcon {
    color: black;
    margin: 0 lg;
  }
}
