@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";
@value dustyGray, lightGrey2, grey from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .authorRow {
    display: flex;
    align-items: center;
    margin-bottom: sm;
  }

  .author {
    color: dustyGray;
    margin-left: sm;
  }

  .date {
    flex: 1;
    color: dustyGray;
    margin-left: sm;
    padding-left: sm;
    border-left: 1px solid lightGrey2;
  }

  .reviewRow {
    display: grid;
    grid-template-columns: 54px 1fr;
    gap: md;
  }

  .imgWrapper {
    display: inherit;
    overflow: hidden;
    border-radius: borderRadiusMedium;
  }

  .reviewContent {
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: grey;
  }
}
