@value sm, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value white from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .carouselContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
    overflow: hidden;
    text-align: center;
    margin-bottom: 16px;
  }

  .mediaContainer {
    position: relative;
    width: 100%;
    min-width: 100%;
    overflow: hidden;
  }

  .carousel {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    transition: 0.2s ease-in transform;

    > img,
    > video {
      display: block;
      width: 100%;
      min-width: 100%;
    }
  }

  .carouselAction {
    border: 0;
    padding: md;
    margin: 0;
    background: transparent;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .carouselActionLeft {
    left: 0;
  }

  .carouselActionRight {
    right: 0;
  }

  .carouselActionContainer {
    background-color: white;
    opacity: 90%;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    padding: 4px;

    svg {
      display: block;
      position: relative;
    }
  }

  .carouselActionContainerLeft svg {
    right: 1px;
  }

  .carouselActionContainerRight svg {
    left: 1px;
  }

  .emptyCarouselSpace {
    height: 8px;
  }

  .dotsContainer {
    position: absolute;
    width: 100%;
    bottom: lg;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
  }

  .dot {
    height: 8px;
    width: 8px;
    background-color: white;
    opacity: 0.5;
    border-radius: sm;
    transition: 0.2s ease-in;
    margin-right: sm;

    &:last-child {
      margin-right: 0;
    }
  }

  .dotActive {
    opacity: 1;
  }
}
