@value xs, sm, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value midGrey, black, lightGrey2 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value DESCRIPTION_LINES_COUNT: 2;

@layer components {
  .row {
    padding: lg 0;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid lightGrey2;
  }

  .itemDetails {
    display: flex;
    margin-bottom: md;
  }

  .image {
    width: 96px;
    margin-right: sm;
  }

  .description {
    display: flex;
    flex-direction: column;
  }

  .productDescription {
    word-break: break-all;
    max-height: calc(1.5 * DESCRIPTION_LINES_COUNT * 0.875rem);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: DESCRIPTION_LINES_COUNT;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    margin-bottom: sm;
  }

  .metadata {
    display: flex;
    align-items: center;
  }

  .dotDivider {
    content: '';
    display: block;
    border-radius: 50%;
    height: xs;
    width: xs;
    margin: 0 xs;
    background: midGrey;
  }

  .fullPriceDiscounted {
    text-decoration: line-through;
    margin-right: xs;
  }

  .boostingFeeRow {
    display: flex;
    justify-content: space-between;
  }

  .boostingFee {
    color: black;
  }
}
