@value md, sm, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value lightGrey1 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value governorBay from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .wrapper {
    display: grid;
    grid-template-columns: xl 1fr;
    gap: md;
    align-items: center;
    padding: md;
    background: lightGrey1;
    border-radius: sm;
  }

  .clothesIcon {
    margin: 0 auto;
  }

  .seeSimilarItemsText {
    color: governorBay;
    text-decoration: none;
  }

  .seeSimilarItemsText:hover,
  .seeSimilarItemsText:focus {
    text-decoration: underline;
  }
}
