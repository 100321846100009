@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value boostingAnimation from "@/modules/styling/zIndex.module.css";

@layer components {
  .boostingOverlay {
    height: 100%;
    top: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.7);
    z-index: boostingAnimation;
    width: 100%;

    @media breakpointMdUp {
        width: unset;
    }
  }

  .animationImg {
    width: 30%;

    @media breakpointMdUp {
        width: 60%;
    }
  }
}
