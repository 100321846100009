@layer components {
  .badgeIcon {
    position: absolute;
    top: 10px;
    right: 2px;
  }
}

@layer overrides {
  .bagIcon {
    margin-bottom: 0;
  }
}
