@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value doveGray, mineShaft, scarlet, alto from "@depop/web-ui-kit/theme/default/colors.module.css";
@value fontFamilyBody from "@depop/web-ui-kit/theme/default/fonts.module.css";

@layer components {
  .flexContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    width: 100%;
  }

  .label {
    margin-bottom: sm;
  }

  .textArea {
    border: 1px solid alto;
    border-radius: 2px;
    border-color: doveGray;
    resize: none;
    width: 100%;
    margin-bottom: sm;
    font-family: fontFamilyBody;
    font-size: 14px;
    padding: sm;
    min-height: 105px;
    color: mineShaft;
   
    &::placeholder {
      color: doveGray;
    }
  }

  .textAreaError {
    border: 1px solid scarlet;

    &:focus {
        outline-color: scarlet;
    }
  }

  .errorMessage {
    margin-top: 0;
  }
}
