@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value midGrey from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .inputWrapper {
    position: relative;
    width: 100%;
    margin: md 0;

    div {
      /* Removing empty space under the UI Kit Input wrapper */
      padding-bottom: 0;
    }
  }

  .shippingCostText {
    position: absolute;
    right: md;
    top: 38px;
  }

  .totalOfferPriceWithFeeQuote {
    color: midGrey;
    margin: sm sm md 0;
    align-self: flex-start;
  }
}
