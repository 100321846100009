@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";
@value black, white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value fontFamilyBody from "@depop/web-ui-kit/theme/default/fonts.module.css";
@value breakpointSmDown, breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value xs from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value searchBarInputWrapperLayer from "@/modules/styling/zIndex.module.css";

@layer components {
  .input--withValue {
    /* Needed to override a media query in Input */
    @media screen and (min-width: 0) {
      padding: 0 104px 0 40px;
    }
  }

  .animatedInputLabelStyles {
    overflow: hidden;
    white-space: nowrap;
    max-width: max-content;
    width: 100%;
  }

  .blinkCaretAnimation {
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      bottom: 2px;
      right: 0;
      width: 1px;
      background: black;
      animation: 1000ms blinkCaret step-end infinite;
    }
  }

  .initialLabelText {
    composes: blinkCaretAnimation animatedInputLabelStyles;
    animation: 400ms typingOut forwards;
    animation-delay: 3000ms;

    &::after {
      display: none;
    }
  }

  .initialLabelText--caretVisible {
    &::after {
      display: block;
    }
  }

  .rotatingLabelText {
    composes: blinkCaretAnimation animatedInputLabelStyles;
    animation: 4000ms typingInOut infinite;
    display: none;

    &::after {
      display: none;
    }
  }

  .rotatingLabelText--visible {
    display: block;
  }

  .rotatingLabelText--caretVisible {
    &::after {
      display: block;
    }
  }

  .labelText {
    composes: animatedInputLabelStyles;
  }

  .searchBarInputWrapper {
    position: relative;
    overflow: hidden;
    flex: 1;
    z-index: searchBarInputWrapperLayer;
  }

  @keyframes typingOut {
    0% {
      width: 100%;
    }

    100% {
      width: 0;
    }
  }

  @keyframes typingInOut {
    0% {
      width: 0;
    }

    20% {
      width: 100%;
    }

    70% {
      width: 100%;
    }

    80%,
    100% {
      width: 0;
    }
  }

  @keyframes blinkCaret {
    0%,
    100% {
      opacity: 0%;
    }

    50% {
      opacity: 100%;
    }
  }

  .input--suggestionsVisible {
    @media breakpointMdUp {
      background: white;
      border-color: white;
    }
  }
}

@layer overrides {
  .inputLabel {
    display: grid;
    grid-template-columns: max-content auto;
    gap: xs;
    align-items: center;
    position: absolute;
    height: 100%;
    left: 42px;
    pointer-events: none;
  }
}
