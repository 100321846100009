@value fontFamilyBody from "@depop/web-ui-kit/theme/default/fonts.module.css";
@value black, white, lightGrey2, lightGrey1, red from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  @keyframes likeHeartbeat {
    0%,
    100% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.2);
    }
  }

  .likeIcon {
    height: 28px;
    width: 28px;
  }

  .emptyLikeIcon {
    color: black;
  }

  .filledLikeIcon {
    color: red;
  }

  .animatedLike {
    animation: likeHeartbeat 0.5s linear;
  }

  .whiteLikeButton {
    color: white;
  }
  
}
