@value midGrey, lightGrey1, overlayDark from "@depop/web-ui-kit/theme/default/colors.module.css";
@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value lightGrey1 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";

@layer components {
  .link {
    text-decoration: none;
  }

  .imgContainer {
    position: relative;
    font-size: 0;
    background-color: lightGrey1;
    border-radius: borderRadiusMedium;

    img {
      border-radius: borderRadiusMedium;
      width: 100%;
    }

    &:hover::after {
      content: '';
      position: absolute;
      display: block;
      inset: 0;
      background-color: overlayDark;
      border-radius: borderRadiusMedium;
    }
  }

  .textContainer {
    margin-top: sm;

    @media breakpointMdUp {
      text-align: center;
    }
  }

  .title {
    font-size: 1rem;
  }

  .text {
    font-size: 0.875rem;
    color: midGrey;
  }
}
