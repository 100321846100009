@value blue, lightGrey2 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointLgDown, breakpointMdUp, breakpointLgUp, breakpointMdDown  from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";

@layer components {
  .seeMoreText {
    color: blue;

    &:hover {
      text-decoration: underline;
    }
  }

  .activeOffersContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: md;
    overflow-x: auto;
    scrollbar-width: none;
    list-style: none;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: md;
    padding-right: md;

    &::-webkit-scrollbar {
      display: none;
    }

    @media breakpointLgDown {
      grid-column-gap: sm;
      grid-template-columns: repeat(2, 1fr);
    }

    @media breakpointMdDown {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .activeOfferListItem {
    border: 1px solid lightGrey2;
    border-radius: borderRadiusMedium;

    @media breakpointMdDown {
      min-width: calc(2.5 * 150px + md);
    }
  }
}
