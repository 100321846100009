@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value lightGrey2 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
  }

  .details {
    display: flex;
    align-items: center;

    &::after {
      content: '•';
      margin: 0 sm;
      white-space: pre;
    }

    &:last-child {
      &::after {
        content: none;
      }
    }
  }

  .detailsWithoutDot {
    display: inline-block;
    margin-right: 0.3em;
  }

  .colourContainer {
    display: inline-block;

    &:not(:last-child) {
      margin-right: sm;
    }
  }

  .colour {
    display: flex;
    align-items: center;
    gap: sm;
  }

  .colourCircle {
    box-sizing: border-box;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid lightGrey2;
    background-color: var(--background-color);
    background-image: var(--background-image);
  }

  .placeholder {
    display: inline-block;
  }
}
