@value black, blue, midGrey from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer overrides {
  .searchIcon {
    width: 27px;
    height: 27px;
    cursor: pointer;
    color: black;

    &:hover {
      color: midGrey;
    }
  }

  .searchIcon--isActive {
    color: blue;

    &:hover {
      color: blue;
    }
  }

  .searchButton {
    padding: 0;
    display: inline-flex;
    margin: auto 0;
    min-width: 44px;
    min-height: 44px;
    justify-content: center;
    align-items: center;
  }
}