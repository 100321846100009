@value xs, sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value a11yYellow, black, white, black_r, black_g, black_b from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointMdUp, breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
    .desktopImage {
        display: none;

        @media breakpointMdUp {
            display: flex;
            flex-grow: 1;
        }

        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }

    .mobileImage {
        display: block;
        width: 100%;

        img {
            object-fit: cover;
            width: 100%;
        }

        @media breakpointMdUp {
            display: none;
        }
    }

    .featuredImageContainer {
        position: relative;
        border-radius: sm;
        overflow: hidden;
        margin-bottom: md;

        a {
            display: block;
        }

        &:hover {
            .desktopImage::after {
                content: '';
                display: block;
                position: absolute;
                inset: 0;
                background-color: rgba(black_r, black_g, black_b, 0.3);
            }
        }

        &:focus-within {
            box-shadow: 0 0 0 4px a11yYellow;

            /* Visible in Windows high-contrast themes: https://stackoverflow.com/a/52616313 */
            outline: 4px dotted transparent;
        }

        @media breakpointLgUp {
            margin-bottom: 0;

            &:nth-child(n + 3) {
                display: block;
            }
        }
    }
}

@layer overrides {
    .descriptionText {
        backdrop-filter: blur(1px);
        background-color: rgba(255, 255, 255, 0.7);
        bottom: 0;
        color: black;
        font-size: 18px;
        font-weight: bold;
        padding: md xs;
        position: absolute;
        text-align: center;
        width: 100%;
    }
}
