@value xs from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .addedWrapper {
    display: flex;
    padding: 10px 14px 11px;
    align-items: center;
    justify-content: center;
    gap: xs;
  }

  .placeholder {
    min-width: 128px;
  }
}
