@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value  breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .scrollableRow {
    /* Ensures that there's spacing at the end of a scrolled row; */
    padding: 0 var(--paddingRight);

    /* Escapes the margin of the page when scrolling the carousel */
    margin: 0 var(--marginRight);
    display: grid;
    grid-template-columns: repeat(var(--itemsPerRow), 1fr);
    gap: sm;
    overflow-x: scroll;
    list-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    li,
    a {
      min-width: var(--minItemWidth);
    }

    @media breakpointMdUp {
      gap: md;
    }
  }
}
