@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .counterOfferModalSuccessContainer {
    text-align: center;
  }

  .counterOfferModalSuccessText {
    margin-top: md;
    text-align: center;
  }

  .counterOfferModalSuccessButton {
    margin-top: md;
    width: 100%;
  }
}
