@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value xs, sm, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value borderRadiusSmall from "@depop/web-ui-kit/theme/default/border.module.css";
@value doveGray, galleryDark, lightGrey1, lightGrey2, midGrey, lightGrey1_r, lightGrey1_g, lightGrey1_b, midGrey_r, midGrey_g, midGrey_b from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .container {
    display: grid;
    grid-template-columns: 120px 1fr;
    gap: lg;
  }

  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: sm;

    @media breakpointMdDown {
      display: -webkit-box;
      white-space: unset;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .nudges {
    margin-bottom: xs;
  }

  .text {
    margin-bottom: sm;
  }

  .productImage {
    line-height: 0;
    border-radius: borderRadiusSmall;
    overflow: hidden;
  }

  .productPlaceholder {
    background-color: lightGrey2;
    border-radius: borderRadiusSmall;
    height: 120px;
    width: 120px;
  }

  .productDetails {
    flex: 1;
    min-width: 0;
  }

  .shippingPriceCaption {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    min-width: 0;
    white-space: nowrap;
    margin-bottom: sm;
  }
}
