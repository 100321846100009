@value breakpointMdUp, breakpointSmDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value xs, sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value blue, white from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .searchTerm {
    margin-left: sm;
    font-weight: normal;

    @media breakpointSmDown {
      margin-left: 0;
      display: grid;
    }
  }

  .seeMoreText {
    color: blue;

    &:hover {
      text-decoration: underline;
    }
  }

  .productListItem {
    list-style: none;
  }
}
