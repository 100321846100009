@value breakpointSmDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value xs, sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";
@value midGrey, red, black from "@depop/web-ui-kit/theme/default/colors.module.css";
@value fontFamilyBody from "@depop/web-ui-kit/theme/default/fonts.module.css";

@layer components {
  .container {
    position: relative;

    @media breakpointSmDown {
      margin-bottom: md;
    }
  }

  .elementWrapper {
    position: relative;

    /* 27px plus the height of the Stripe input equals the total height of our InputV2 component */
    padding: 27px md sm;
    width: 100%;
    border: 1px solid #acacac;
    border-radius: borderRadiusMedium;
    cursor: text;
    outline-style: solid;
    outline-width: 0;
    outline-offset: 0;
    outline-color: unset;
  }

  .elementWrapperError {
    outline-width: 1px;
    outline-offset: -1px;
    outline-color: red;
  }

  .elementWrapperFocusedBorder {
    outline-width: 2px;
    outline-offset: -1px;
    outline-color: black;
  }

  .elementLabel {
    position: absolute;
    transform: var(--transform);
    transition: all 0.2s ease-in-out;
    font-family: fontFamilyBody;
    color: midGrey;
    cursor: text;
    font-size: var(--fontSize);
  }

  .elementStyle {
    color: black;
    font-family: fontFamilyBody;
    font-size: 16px;
  }

  .elementStyleInvalid {
    color: red;
  }

  .errorMessage {
    color: red;
    display: inline-block;
    margin-top: xs;
    margin-left: md;
  }

  .trailingIconWrapper {
    position: absolute;
    right: md;

    /* Position the trailing icon in the center of the Stripe input field */
    top: calc(50% - 10px);

    svg {
      height: auto;
      width: 22px;
      color: #acacac;

      &:focus {
        color: black;
      }
    }
  }
}
