@value black from "@depop/web-ui-kit/theme/default/colors.module.css";
@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer overrides {
  .feedbackButton {
    display: block;
    padding: 0;

    @media breakpointSmDown {
      /* Touch targets must be 24px large for accessibility */
      height: 24px;
    }

    span {
      display: flex;
      align-items: center;
      font-weight: normal;

      &:hover {
        text-decoration: underline;
        color: black;
      }
    }

    svg {
      display: flex;
      align-items: center;
      margin-right: 2px;

      &:last-of-type {
        margin-right: sm;
      }
    }
  }
}
