@value blue from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .seeMoreText {
    color: blue;
  }

  .productListItem {
    list-style: none;
  }
}
