@value md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .form {
    display: flex;
    flex-direction: column;
    gap: lg;
  }

  .panel {
    width: 100%;

    @media breakpointMdUp {
      margin-bottom: lg;
    }
  }

  .orderSummaryPanel {
    min-height: 146px;
    display: flex;
    flex-direction: column;
    gap: md;

    @media breakpointMdUp {
      display: none;
    }
  }

  .loadingContainer {
    display: flex;
    align-items: center;
    inset: 0;
  }
}
