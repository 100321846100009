@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .text {
    display: inline-block;
    text-transform: uppercase;

    @media breakpointMdDown {
      margin: md 0 0;
    }
  }
}
