@value sm, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .header--large {
    margin-bottom: md;

    @media breakpointSmUp {
      margin-bottom: lg;
    }
  }

  .header--small {
    margin-bottom: sm;

    @media breakpointSmUp {
      margin-bottom: md;
    }
  }

  .title {
    font-size: 1.125rem; /* 18px */
  }

  .title--large {
    @media breakpointSmUp {
      font-size: 1.5rem; /* 24px */
    }
  }

  .subtitle--large {
    font-size: 1rem; /* 18px */
  }

  .subtitle--small {
    font-size: 0.875rem; /* 14px */
  }
}