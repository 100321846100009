@value md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value lightGrey2, midGrey from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointSmDown, breakpointSmUp, breakpointMdDown, breakpointMdUp, breakpointXlUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value COLUMN_WIDTH_SMALL, COLUMN_WIDTH_MEDIUM, COLUMN_WIDTH_LARGE from "@/modules/receipts/styles.module.css";

@layer components {
    .headerRow {
        width: 100%;
        display: flex;
        justify-content: space-between;
        background-color: transparent;
        padding: md md md lg;
        color: lightGrey2;
        border-bottom: 1px solid lightGrey2;
        cursor: default;

        @media breakpointSmDown {
            display: none;
        }

        @media breakpointMdDown {
            padding: md 0;
            justify-content: flex-start;
        }
    }

    .wrapper {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;

        @media breakpointSmDown {
            flex-direction: column;
        }
    }

    .userInfoHeading {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: unset;
        position: relative;

        @media breakpointSmUp {
            flex: 1;
        }

        @media breakpointMdUp {
            width: COLUMN_WIDTH_MEDIUM;
        }

        @media breakpointXlUp {
            width: COLUMN_WIDTH_LARGE;
        }
    }

    .transactionDateHeading {
        display: flex;
        flex: 0 0 COLUMN_WIDTH_SMALL;

        @media breakpointSmDown {
            flex: 1
        }
    }

    .transactionDateHeading p {
        @media breakpointSmDown {
            text-transform: uppercase;
            font-size: 12px;
        }
    }

    .shippingProvidersHeading {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 0 0 COLUMN_WIDTH_LARGE;

        @media breakpointSmDown {
            display: none;
        }
    }

    .shippingStatusHeading {
        display: flex;
        flex: 0 0 COLUMN_WIDTH_LARGE;

        @media breakpointSmDown {
            flex: 1;
        }
    }

    .shippingStatusHeading p {
        @media breakpointSmDown {
            font-size: 12px;
            color: midGrey;
        }
    }

    .totalHeading {
        display: flex;
        flex: 0 0 COLUMN_WIDTH_LARGE;
        text-transform: capitalize;

        @media breakpointSmDown {
            display: none;
        }
    }

    .manageHeading {
        display: flex;
        flex: 0 0 COLUMN_WIDTH_SMALL;
        text-align: right;
        text-transform: capitalize;

        @media breakpointSmDown {
            display: none;
        }
    }
}