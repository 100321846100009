@value xs, sm, md, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value green, white, lightGrey2, black  from "@depop/web-ui-kit/theme/default/colors.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";

@layer components {
  .wrapper {
    display: flex;
    gap: md;
    width: 100%;
  }

  .buttonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;
  }

  .button {
    padding-top: sm;
    padding-bottom: sm;
    border-radius: borderRadiusMedium;
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
    background: white;
    border-width: 2px;
    border-style: solid;
    border-color: lightGrey2;
    transition: 0.2s ease all;

    &:hover {
      border-color: black;
    }

    span {
      display: block;
      color: inherit;
    }
  }

  .buttonSelected {
    border-color: black;

    &:hover {
      border-color: black;
    }
  }

  .buttonRecommended {
    border-color: rgba(41, 127, 0, 0.3);
    background: rgba(214, 244, 200, 0.3);
    color: rgba(37, 109, 28, 1);

    &:hover {
      border-color: green;
    }
  }

  .buttonSelectedRecommended {
    border-color: green;
  }

  .recommendationText {
    margin-top: xs;
    color: green;
  }
}


