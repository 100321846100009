@value fontFamilySplash from "@depop/web-ui-kit/theme/default/fonts.module.css";
@value xs, md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmDown, breakpointSmUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-wrap: balance;
  }

  .title {
    font-size: 32px;
    align-self: center;
    font-family: fontFamilySplash;
    margin-bottom: md;

    @media breakpointSmDown {
      font-size: 18px;
    }
  }

  .link {
    text-decoration: none;
  }

  .ctaButton {
    font-family: fontFamilySplash;
    border-radius: xs;
  }
}
