@value lg from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .mainError {
        margin: lg 0;
    }

    .subtitle {
        margin-top: lg;
    }
}