@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .addressInputWrapper {
    margin-bottom: md;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    /* This is gross, no easy way to target the padding of the Input component */
    & > div {
      & > div {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
}
