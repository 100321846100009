@value sm, md, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmUp, breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value searchResultsStackingContext from "@/modules/styling/zIndex.module.css";

@layer components {
  .listItem {
    list-style-type: none;
  }

  .productGrid {
    position: relative;
    z-index: searchResultsStackingContext;
    display: grid;
    gap: sm;
    grid-template-columns: repeat(2, 1fr);

    @media breakpointSmUp {
      grid-template-columns: repeat(3, 1fr);
    }

    @media breakpointMdUp {
      grid-template-columns: repeat(4, 1fr);
      gap: md;
    }
  }

  .loaderWrapper {
    padding-top: xl;
    padding-bottom: xl;
  }
}
