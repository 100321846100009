@value lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .modules {
    display: flex;
    flex-direction: column;
    gap: lg;

    @media breakpointSmUp {
      gap: 40px;
    }
  }
}
