@value fontFamilyBody from "@depop/web-ui-kit/theme/default/fonts.module.css";
@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .saveIcon {
    height: 28px;
    width: 28px;
    margin: auto;
  }
}
