@layer components {
  .styles_heroButton__kjLfS {
    max-width: 250px;
  }
}


@layer components {
  .styles_container__WgMSb {
    padding: 16px 32px;
    text-align: center;
  }
}

@layer components {
  .styles_container__SGQMg {
    text-align: center;
    margin: 120px 0;
  }

  .styles_title__DN8io {
    margin-bottom: 8px;
  }
}
/*
  CSS modules support for using value variables in the rgba helper
  is very limited which is why we unfortunately have to supply both
  hex and rgb values for each color so we can do rgba manipulation
*/
/**
 * These are the master values for breakpoint behaviour.
 *
 *    If you change any, 
 *    YOU MUST UPDATE `breakpoints.constants.ts`!
 *
 */

/* 
  Note: The up/down utilities below can only be used within ui-kit. They cannot be used directly in next.js.
  See: https://github.com/vercel/next.js/discussions/16050#discussioncomment-49022

  Within a next.js context we need to declare the media query manually and then interpolate
  with the exported values above. For example:
  
  @media (max-width: smMaxPx) {
    .bg {
      background-color: green;
    }
  }
*/
@layer components {
  .styles_wrapper__EZ6yz {
    display: inline-flex;
    width: 80%;
    text-align: center;
    align-items: center;
  }

    @media (min-width: calc(576 * 1px)) {
  .styles_wrapper__EZ6yz {
      width: auto
  }
    }

  .styles_icon__7oEVp {
    margin-right: 8px;
  }

  .styles_buyerProtectionText__aO0zc {
    text-align: left;
    color: #262626;
  }

  .styles_buyerProtectionLink__HShyj {
    text-decoration: none;
    color: #2960AF;
    white-space: nowrap;
  }

    .styles_buyerProtectionLink__HShyj:hover {
      text-decoration: underline;
    }
}

@layer components {
  .styles_dayText__uJ2Wo {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }
}

@layer components {
  .styles_container__jiVWT {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 120px 0;
  }

    .styles_container__jiVWT p {
      margin-bottom: 8px;
    }

    .styles_container__jiVWT a {
      margin-top: 16px;
    }

    @media (max-width: calc((768 - 1) * 1px)) {
  .styles_container__jiVWT {
      margin: 16px 0
  }
    }

  .styles_icon__AKnH0 {
    margin-bottom: 8px;
    color: #747474;
  }
}

@layer components {
  .styles_container__fZypU {
    position: relative;

  }

  .styles_container--collapsed__nqKQB {
    margin-bottom: 8px;
  }

    @media (min-width: calc(768 * 1px)) {

  .styles_container--collapsed__nqKQB {
      margin-bottom: 16px
  }
    }

  .styles_container--expanded__La9eu {
    margin-bottom: 24px;

  }

  .styles_textWrapper__v3kxJ {
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: pre-line;
  }

  .styles_textWrapper--collapsed__YnecK {
    display: -webkit-box;
    overflow: hidden;
    max-height: calc(10 * 1.5 * 0.875rem);
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
  }

    @media (min-width: calc(768 * 1px)) {

  .styles_textWrapper--collapsed__YnecK {
      max-height: calc(7 * 1.5 * 0.875rem);
      -webkit-line-clamp: 7
  }
    }

  .styles_showMoreContainer__b9oxr {
    display: flex;
    justify-content: flex-end;
  }

  .styles_showMoreButton__96DRQ {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    bottom: 0;
    right: 0;
    z-index: calc(0 + 1);
  }

    .styles_showMoreButton__96DRQ::after {
      position: absolute;
      z-index: calc(0 + -1);
      right: 0;
      pointer-events: none;
      height: 100%;
      width: 150%;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255) 30%);
    }

  .styles_showMoreButton--expanded__0AoGq {
    position: relative;
  }

    .styles_showMoreButton--expanded__0AoGq::after {
      content: none;
    }

  .styles_showMoreButton--collapsed__88C4_ {
    position: absolute;
  }

    .styles_showMoreButton--collapsed__88C4_::after {
      content: "";
    }
}

@layer overrides {
  .styles_link___0GD_ {
    font-weight: bold;
    color: inherit;
    cursor: pointer;
  }

    .styles_link___0GD_:hover {
      text-decoration: underline;
    }
}
@layer components {
  .styles_inputWrapper__x_hgA {
    position: relative;
    width: 100%;
    margin: 16px 0;
  }

    .styles_inputWrapper__x_hgA div {
      /* Removing empty space under the UI Kit Input wrapper */
      padding-bottom: 0;
    }

  .styles_shippingCostText__a8vVM {
    position: absolute;
    right: 16px;
    top: 38px;
  }

  .styles_totalOfferPriceWithFeeQuote__yHqe8 {
    color: #747474;
    margin: 8px 8px 16px 0;
    align-self: flex-start;
  }
}

@layer components {
  .styles_counterOfferModalSuccessContainer__csZfG {
    text-align: center;
  }

  .styles_counterOfferModalSuccessText__qD_KW {
    margin-top: 16px;
    text-align: center;
  }

  .styles_counterOfferModalSuccessButton__6WIR6 {
    margin-top: 16px;
    width: 100%;
  }
}

@layer components {
  .styles_counterOfferModalContainer__K4g0i {
    padding: 8px 16px 16px;
  }

  .styles_counterOfferModalHeadingContainer__I6MIS {
    display: flex;
    width: 100%;
    justify-content: center;
    left: 0;
    right: 0;
    margin-top: 4px;
  }
}

@layer overrides {
  .styles_unstyledLink__DsttP {
    text-decoration: none;
  }
}

@layer components {
  .styles_container__NdYm1 {
    display: flex;
    flex-direction: column;
    text-align: center;
    border-bottom: 1px solid #D7D7D7;
    padding: 16px;
    gap: 8px;
  }

  .styles_depopShippingDetails__ycrU_ {
    margin-top: 8px;
  }

  .styles_moreInfoLink__80yxo {
    color: #2960AF;
  }

  .styles_paymentReceived__iAMJ5,
  .styles_paymentRefunded__04ztl {
    margin-bottom: 8px;
  }

  .styles_statementText__f5ZWt {
    padding: 16px 0;
    background-color: #F3F3F3;
    margin-bottom: 16px;
  }

  .styles_taxSummary__Ki5pa {
    margin-top: 8px;
  }

  .styles_table__mVwWD {
    border-collapse: collapse;
    margin-bottom: 16px;
    width: 100%;
  }

  .styles_row__dkeM_ {
    border-bottom: 1px solid #AFAFAF;
  }

    .styles_row__dkeM_:last-child {
      border-bottom: none;
    }

      .styles_row__dkeM_:last-child * {
        font-weight: bold;
      }

  .styles_label__xwoLD {
    text-align: left;
  }

  .styles_labelText__18elH,
  .styles_priceText__GHt97 {
    color: #262626;
  }

  .styles_price__5FslO {
    text-align: right;
  }

  .styles_label__xwoLD,
  .styles_price__5FslO {
    padding: 8px 0;
  }
}

@layer components {
  .styles_container__5vUYa {
    display: flex;
    flex-direction: column;
    text-align: center;
    border-bottom: 1px solid #D7D7D7;
    padding: 16px;
    gap: 8px;
  }
}

@layer components {
  .styles_container__Jonqj {
    padding: 8px 32px;
    text-align: center;
    border-bottom: 1px solid #D7D7D7;
  }

  .styles_content__Dpo5o {
    display: flex;
  }

  .styles_image__nuVfa {
    height: fit-content;
  }

  .styles_productDetailContainer__cnEKb {
    display: block;
    margin: 16px 0;
  }

  .styles_shippingContainer__CFKWD {
    display: block;
    margin: 24px 0 8px 0;
    width: 100%;
  }

  .styles_summary__y0G08 {
    display: flex;
    flex-wrap: wrap;
    gap: 0 16px;
    margin-top: 16px;
  }

  .styles_textContainer__6o62q {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    margin-left: 16px;
    text-align: left;
  }
    .styles_bold__kOO91 * {
      font-weight: bold;
    }
}

@layer components {
  .styles_divider__W_OqT {
    margin: 16px 0;
    background: #D7D7D7;
    border: none;
    height: 1px;
  }

    @media (min-width: calc(768 * 1px)) {
  .styles_divider__W_OqT {
      margin: 24px 0
  }
    }
}

@layer components {
  .styles_indicator__4SV2i {
    background: #E20020;
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 100%;
    position: absolute;
    top: 0;
    right: -1px;
  }
}

@layer components {
  .styles_container__P6Lqu {
    display: grid;
    grid-template-columns: 120px 1fr;
    gap: 24px;
  }

  .styles_wrapper__UJyvS {
    flex: 1;
    min-width: 0;
  }

  .styles_placeholderWrapper__tLjSs {
    margin-bottom: 8px;
  }
}

@layer components {
  .styles_container__q_yL9 {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
  }

  .styles_details__G_k8d {
    display: flex;
    align-items: center;
  }

    .styles_details__G_k8d::after {
      content: '•';
      margin: 0 8px;
      white-space: pre;
    }
      .styles_details__G_k8d:last-child::after {
        content: none;
      }

  .styles_detailsWithoutDot__U_PKw {
    display: inline-block;
    margin-right: 0.3em;
  }

  .styles_colourContainer__yvR0z {
    display: inline-block;
  }

    .styles_colourContainer__yvR0z:not(:last-child) {
      margin-right: 8px;
    }

  .styles_colour__MR4Kz {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .styles_colourCircle__o_Hs1 {
    box-sizing: border-box;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid #D7D7D7;
    background-color: var(--background-color);
    background-image: var(--background-image);
  }

  .styles_placeholder__Hw_O0 {
    display: inline-block;
  }
}

@layer components {
  .styles_text__AMrZL {
    display: inline-block;
    text-transform: uppercase;
  }

    @media (max-width: calc((768 - 1) * 1px)) {
  .styles_text__AMrZL {
      margin: 16px 0 0
  }
    }
}

@layer components {
    @media (min-width: calc(768 * 1px)) {
  .styles_attributes__ULMlm {
      margin-bottom: 16px
  }
    }
}

@layer components {
  .styles_container__RYRVq {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
  }
}



@layer components {
  .styles_footerUpperContainer__t0HW3 {
    background-color: #F3F3F3;
  }

  .styles_footerUpperContent__vU4xM {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    max-width: 1280px;
    margin: 0 auto;
  }

    @media (min-width: calc(768 * 1px)) {

  .styles_footerUpperContent__vU4xM {
      flex-direction: row;
      justify-content: space-between;
      padding: 0 0 16px 0
  }
    }

  .styles_footerColumnMobileContainer__I6OTH {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

    @media (min-width: calc(768 * 1px)) {

  .styles_footerColumnMobileContainer__I6OTH {
      display: none
  }
    }

  .styles_footerColumnDesktopContainer__e54rn {
    display: none;
    flex-direction: column;
    gap: 48px;
    flex-grow: 2;
  }

    @media (min-width: calc(768 * 1px)) {

  .styles_footerColumnDesktopContainer__e54rn {
      display: flex;
      flex-direction: row
  }
    }

  .styles_footerColumn__qNKVm {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .styles_footerColumTitle__stShO {
    padding: 24px 16px;
  }

  .styles_footerSocialColumn__TAEcE {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 16px;
  }

    @media (min-width: calc(768 * 1px)) {

  .styles_footerSocialColumn__TAEcE {
      align-items: flex-end;
      justify-content: flex-end;
      padding: 0
  }
    }

  .styles_footerSocialContainer__OSwB9 {
    display: flex;
    gap: 24px;
    align-items: center;
  }

    @media (min-width: calc(768 * 1px)) {

  .styles_footerSocialContainer__OSwB9 {
      padding-right: 16px
  }
    }

  .styles_socialLink__BdpPt {
    transition: opacity 0.2s ease;
  }

    .styles_socialLink__BdpPt:hover {
      opacity: 0.5;
    }

  .styles_footerLowerContainer__NgNfM {
    background-color: #D7D7D7;
  }

  .styles_footerLowerContent__wuvh7 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1280px;
    margin: 0 auto;
  }

    @media (min-width: calc(768 * 1px)) {

  .styles_footerLowerContent__wuvh7 {
      justify-content: space-between;
      padding: 16px 0;
      flex-wrap: wrap
  }
    }

  .styles_footerLowerDesktopColumn__AGfYw {
    display: none;
  }

    @media (min-width: calc(768 * 1px)) {

  .styles_footerLowerDesktopColumn__AGfYw {
      display: flex;
      flex-direction: row;
      align-items: center
  }
    }

  .styles_footerLocalisationContainer__NjS8l {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

    @media (min-width: calc(768 * 1px)) {

  .styles_footerLocalisationContainer__NjS8l {
      flex-direction: row;
      width: unset
  }
    }

  .styles_footerLinkWrapper__UuUFV {
    padding: 16px;
  }

  .styles_footerLink__z7CcF {
    display: inline-block;
    text-decoration: none;
    color: #262626;
  }

    .styles_footerLink__z7CcF:hover {
      text-decoration: underline;
    }

  .styles_footerCookieButton__LEx9H {
    display: inline-block;
    text-decoration: none;
    color: #262626;
    padding: 16px;
    background-color: unset;
    border: none;
    cursor: pointer;
    align-self: flex-start;
  }

    .styles_footerCookieButton__LEx9H:hover {
      text-decoration: underline;
    }

  .styles_footerAccordianButton__SR274 {
    display: flex;
    justify-content: space-between;
    background-color: unset;
    border: none;
    cursor: pointer;
    padding: 24px 0;
  }
}

@layer overrides {
  .styles_selectLocation__lRv3a {
    border: none;
    display: flex;
  }

    @media (min-width: calc(768 * 1px)) {
  .styles_selectLocation__lRv3a {
      width: var(--width);
      margin-right: 4px
  }

      .styles_selectLocation__lRv3a:hover,
      .styles_selectLocation__lRv3a:focus-within {
        background-color: #F3F3F3;
      }
    }

    .styles_selectLocation__lRv3a > svg {
      z-index: unset;
      cursor: pointer;
      pointer-events: none;
      right: 16px;
      position: absolute;
      top: 50%;
      margin-top: -sm;
      width: 16px;
      height: 16px;
    }

    .styles_selectLocation__lRv3a > select {
      padding: 16px 0 16px 16px;
      border-radius: 16px;
      cursor: pointer;
      font-family: var(--font-GtAmericaExtended), -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    }

      .styles_selectLocation__lRv3a > select:focus-visible {
        outline: none;
      }

  .styles_selectLanguage__RFdkX {
    border: none;
    display: flex;
  }

    @media (min-width: calc(768 * 1px)) {

  .styles_selectLanguage__RFdkX {
      width: var(--width)
  }

      .styles_selectLanguage__RFdkX:hover,
      .styles_selectLanguage__RFdkX:focus-within {
        background-color: #F3F3F3;
      }
    }

    .styles_selectLanguage__RFdkX > svg {
      z-index: unset;
      cursor: pointer;
      pointer-events: none;
      right: 16px;
    }

    .styles_selectLanguage__RFdkX > select {
      padding: 16px 0 16px 16px;
      border-radius: 16px;
      cursor: pointer;
      font-family: var(--font-GtAmericaExtended), -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    }

      .styles_selectLanguage__RFdkX > select:focus-visible {
        outline: none;
      }
}

@layer overrides {
  .styles_textButton--login__nFo3d {
    background-color: #FFFFFF;
    color: #262626;
    border: none;
    padding-left: 8px;
    padding-right: 8px;
  }

    @media (min-width: calc(1024 * 1px)) {
  .styles_textButton--login__nFo3d {
      padding-left: 0;
      padding-right: 0
  }
    }

  .styles_textButton--signup__Se36i {
    padding-left: 8px;
    padding-right: 8px;
  }

    @media (min-width: calc(1024 * 1px)) {

  .styles_textButton--signup__Se36i {
      background-color: #FFFFFF;
      color: #262626;
      padding-left: 16px;
      padding-right: 16px
  }
    }
}

@layer components {
  .styles_sellLinkWrapper__GRd6D {
    position: relative;
    display: none;
  }

    .styles_sellLinkWrapper__GRd6D span {
      right: 4px;
      top: 8px;
    }

    @media (min-width: calc(768 * 1px)) {
  .styles_sellLinkWrapper__GRd6D {
      display: flex
  }
    }
}

@layer components {
  .styles_badgeIcon__eby_l {
    position: absolute;
    top: 10px;
    right: 2px;
  }
}

@layer overrides {
  .styles_bagIcon__vlBX2 {
    margin-bottom: 0;
  }
}

@layer components {
  .styles_heading__1mhsr {
    margin-right: 8px;
  }

  .styles_wrapper__WHk_i {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .styles_status__FVWOP {
    margin-top: 10px;
  }
}

@layer components {
  .styles_carouselContainer__2riiz {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
    overflow: hidden;
    text-align: center;
    margin-bottom: 16px;
  }

  .styles_mediaContainer___wKoB {
    position: relative;
    width: 100%;
    min-width: 100%;
    overflow: hidden;
  }

  .styles_carousel__Fbune {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    transition: 0.2s ease-in transform;
  }

    .styles_carousel__Fbune > img,
    .styles_carousel__Fbune > video {
      display: block;
      width: 100%;
      min-width: 100%;
    }

  .styles_carouselAction__8P_eD {
    border: 0;
    padding: 16px;
    margin: 0;
    background: transparent;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .styles_carouselActionLeft__6M0Kw {
    left: 0;
  }

  .styles_carouselActionRight__6Kae7 {
    right: 0;
  }

  .styles_carouselActionContainer__urkY6 {
    background-color: #FFFFFF;
    opacity: 90%;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    padding: 4px;
  }

    .styles_carouselActionContainer__urkY6 svg {
      display: block;
      position: relative;
    }

  .styles_carouselActionContainerLeft__r8tsk svg {
    right: 1px;
  }

  .styles_carouselActionContainerRight__yYzqx svg {
    left: 1px;
  }

  .styles_emptyCarouselSpace__eG4SF {
    height: 8px;
  }

  .styles_dotsContainer__bUXei {
    position: absolute;
    width: 100%;
    bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
  }

  .styles_dot__EmCO_ {
    height: 8px;
    width: 8px;
    background-color: #FFFFFF;
    opacity: 0.5;
    border-radius: 8px;
    transition: 0.2s ease-in;
    margin-right: 8px;
  }

    .styles_dot__EmCO_:last-child {
      margin-right: 0;
    }

  .styles_dotActive__JNKge {
    opacity: 1;
  }
}

@layer components {
  .styles_img__AjH_f {
    width: 100%;
    margin-bottom: 16px;
  }

    @media (min-width: calc(768 * 1px)) {
  .styles_img__AjH_f {
      margin-bottom: 0
  }
    }

  .styles_mobileViewContainer__fLf9E {
    margin: 0 -16px;
  }

    @media (min-width: calc(768 * 1px)) {

  .styles_mobileViewContainer__fLf9E {
      display: none
  }
    }

  .styles_desktopViewContainer__V9YOG {
    position: relative;
    display: block;
  }

    @media (max-width: calc((768 - 1) * 1px)) {

  .styles_desktopViewContainer__V9YOG {
      display: none
  }
    }

  .styles_imageContainer__XCWwf {
    width: 100%;
    position: relative;
    min-width: 100%;
    background-color: #F3F3F3;
  }

    @media (min-width: calc(768 * 1px)) {

  .styles_imageContainer__XCWwf {
      margin-bottom: 8px
  }
    }

  .styles_imageContainer__XCWwf img {
    display: block;
  }

  .styles_imageItem__UWJs6 {
    background-image: var(--background-image);
    background-size: cover;
  }

  .styles_imageItemNonSquare__VJ0R6 {
    height: 100%;
    object-fit: contain;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

@layer components {
  .styles_videoContainer__lWTSw {
    position: relative;
    width: 100%;
    min-width: 100%;
    padding-top: 100%;
    cursor: pointer;
  }

    .styles_videoContainer__lWTSw video {
      width: 100%;
      background-color: #D7D7D7;
    }

    .styles_videoContainer__lWTSw:hover button {
      opacity: 1;
    }

  .styles_videoBackground__UtrmB {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .styles_videoSpinner__GjYig {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 96px;
    height: 96px;
    margin-top: -48px;
    margin-left: -48px;
  }

  .styles_playButtonBase__EMsUO {
    border: none;
    background: none;
    opacity: 0.6;
    outline: 0;
    cursor: pointer;
    transition: 0.2s all ease-in;
  }

    .styles_playButtonBase__EMsUO:hover {
      opacity: 1;
    }

    .styles_playButtonBase__EMsUO svg {
      width: 100%;
      margin: 0;
      padding: 0;
      border: 0;
      display: block;
    }

  .styles_videoPlayButton__tCJQg {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    text-align: center;
    position: absolute;
    inset: 0;
  }

  .styles_audioPlayButton__AmSx_ {
    position: absolute;
    top: 2.5%;
    left: 5%;
    cursor: pointer;
    color: #FFFFFF;
  }
}

@layer components {
  .styles_deleteButton__fvoY5 {
    display: inline-block;
    left: 0;
    align-self: start;
    padding: 16px 0 8px 0;
  }

  .styles_deleteText__Z79gp {
    color: #3d37bd;
  }

  .styles_spinner__wehdP {
    color: #3d37bd;
    margin-top: 16px;
  }
}

@layer components {
    .styles_actionMenuItemStyle__PRHIk a, .styles_actionMenuItemStyle__PRHIk button {
      width: 100%;
      color: #262626;
      font-size: 0.875rem;
    }
    .styles_actionMenuItemStyleMinimal__ifk_a a, .styles_actionMenuItemStyleMinimal__ifk_a button {
      text-align: left;
      padding: 8px 16px 8px 8px;
      margin: 0;
      border-bottom: 1px solid #F3F3F3;
    }
    
      .styles_actionMenuItemStyleMinimal__ifk_a a:hover,
      .styles_actionMenuItemStyleMinimal__ifk_a a:focus,
      .styles_actionMenuItemStyleMinimal__ifk_a a:active,
      .styles_actionMenuItemStyleMinimal__ifk_a button:hover,
      .styles_actionMenuItemStyleMinimal__ifk_a button:focus,
      .styles_actionMenuItemStyleMinimal__ifk_a button:active {
        background: #F3F3F3;
        text-decoration: none;
        outline: none;
        border-bottom: 1px solid #F3F3F3;
      }
    
      .styles_actionMenuItemStyleMinimal__ifk_a a:last-of-type, .styles_actionMenuItemStyleMinimal__ifk_a button:last-of-type {
        border-bottom: 0;
      }
    .styles_actionMenuItemStyleCenter__BFya6 a, .styles_actionMenuItemStyleCenter__BFya6 button {
      text-align: center;
      padding: 8px 16px;
    }
    .styles_actionMenuItemStyleBlock__RR4aK a, .styles_actionMenuItemStyleBlock__RR4aK button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
      padding: 16px;
      border-bottom: 1px solid #F3F3F3;
    }
    
      .styles_actionMenuItemStyleBlock__RR4aK a a, .styles_actionMenuItemStyleBlock__RR4aK button a {
        color: #262626;
        text-decoration: none;
      }
    
      .styles_actionMenuItemStyleBlock__RR4aK a:visited, .styles_actionMenuItemStyleBlock__RR4aK button:visited {
        color: #262626;
      }
    
      .styles_actionMenuItemStyleBlock__RR4aK a:hover,
      .styles_actionMenuItemStyleBlock__RR4aK a:hover a,
      .styles_actionMenuItemStyleBlock__RR4aK a:focus,
      .styles_actionMenuItemStyleBlock__RR4aK a:focus a,
      .styles_actionMenuItemStyleBlock__RR4aK button:hover,
      .styles_actionMenuItemStyleBlock__RR4aK button:hover a,
      .styles_actionMenuItemStyleBlock__RR4aK button:focus,
      .styles_actionMenuItemStyleBlock__RR4aK button:focus a {
        background-color: #2960AF;
        color: #FFFFFF;
        outline: none;
        text-decoration: none;
      }
    
      .styles_actionMenuItemStyleBlock__RR4aK a:last-child, .styles_actionMenuItemStyleBlock__RR4aK button:last-child {
        border-bottom: none;
      }

  .styles_wrapper__87SHx {
    position: fixed;
    box-shadow: 0 0 5px 0 #D7D7D7;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #FFFFFF;
    border-radius: 2px;
    overflow: hidden;
    z-index: 1;
    min-width: 180px;
    max-width: 400px;
  }
  
  .styles_mobileOverlay__ifxjz {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    inset: 0 0 0 0;
    background: #747474;
    z-index: 3;
  }
  
  .styles_mobileWrapper__PxHH2 {
    display: flex;
    flex-direction: column;
    min-width: 180px;
    background: #FFFFFF;
    z-index: 1;
    border-radius: 8px;
  } 
}


@layer components {
  .styles_seeMoreText__J0lKB {
    color: #2960AF;
  }

    .styles_seeMoreText__J0lKB:hover {
      text-decoration: underline;
    }

  .styles_activeOffersContainer__h7kia {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 16px;
    overflow-x: auto;
    scrollbar-width: none;
    list-style: none;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
  }

    .styles_activeOffersContainer__h7kia::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: calc((1024 - 1) * 1px)) {

  .styles_activeOffersContainer__h7kia {
      grid-column-gap: 8px;
      grid-template-columns: repeat(2, 1fr)
  }
    }

    @media (max-width: calc((768 - 1) * 1px)) {

  .styles_activeOffersContainer__h7kia {
      grid-template-columns: repeat(3, 1fr)
  }
    }

  .styles_activeOfferListItem__PASMg {
    border: 1px solid #D7D7D7;
    border-radius: 4px;
  }

    @media (max-width: calc((768 - 1) * 1px)) {

  .styles_activeOfferListItem__PASMg {
      min-width: calc(2.5 * 150px + 16px)
  }
    }
}

@layer components {
  .styles_addedWrapper__DSRMQ {
    display: flex;
    padding: 10px 14px 11px;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  .styles_placeholder__UyfEw {
    min-width: 128px;
  }
}

@layer components {
  .styles_container__XZqlF {
    color: #266D1C;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    gap: 4px;
  }

    @media (max-width: calc((768 - 1) * 1px)) {
  .styles_container__XZqlF {
      margin-top: 0
  }
    }

  .styles_shippingMessage__Lh7RW {
    color: #266D1C;
  }
}

@layer components {
  .styles_container__uRr75 {
    position: relative;
  }

    @media (max-width: calc((576 - 1) * 1px)) {
  .styles_container__uRr75 {
      margin-bottom: 16px
  }
    }

  .styles_elementWrapper__5rzKz {
    position: relative;

    /* 27px plus the height of the Stripe input equals the total height of our InputV2 component */
    padding: 27px 16px 8px;
    width: 100%;
    border: 1px solid #acacac;
    border-radius: 4px;
    cursor: text;
    outline-style: solid;
    outline-width: 0;
    outline-offset: 0;
    outline-color: unset;
  }

  .styles_elementWrapperError__faOGv {
    outline-width: 1px;
    outline-offset: -1px;
    outline-color: #E20020;
  }

  .styles_elementWrapperFocusedBorder__NZOkI {
    outline-width: 2px;
    outline-offset: -1px;
    outline-color: #262626;
  }

  .styles_elementLabel__bJaNY {
    position: absolute;
    transform: var(--transform);
    transition: all 0.2s ease-in-out;
    font-family: var(--font-GtAmericaExtended), -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    color: #747474;
    cursor: text;
    font-size: var(--fontSize);
  }

  .styles_elementStyle__QSPof {
    color: #262626;
    font-family: var(--font-GtAmericaExtended), -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    font-size: 1rem;
  }

  .styles_elementStyleInvalid__EWvln {
    color: #E20020;
  }

  .styles_errorMessage__0v6nF {
    color: #E20020;
    display: inline-block;
    margin-top: 4px;
    margin-left: 16px;
  }

  .styles_trailingIconWrapper__DWf1a {
    position: absolute;
    right: 16px;

    /* Position the trailing icon in the center of the Stripe input field */
    top: calc(50% - 10px);
  }

    .styles_trailingIconWrapper__DWf1a svg {
      height: auto;
      width: 22px;
      color: #acacac;
    }

      .styles_trailingIconWrapper__DWf1a svg:focus {
        color: #262626;
      }
}

@layer components {
  .styles_columns__sca2v {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-top: 16px;
    grid-row-gap: 16px;
  }

  .styles_postCodeInput__vBU9s {
    margin-top: 16px;
  }
}


@layer components {
  .styles_container__fk137 {
    display: grid;
    grid-template-columns: 120px 1fr;
    gap: 24px;
  }

  .styles_description__4oA_s {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 8px;
  }

    @media (max-width: calc((768 - 1) * 1px)) {

  .styles_description__4oA_s {
      display: -webkit-box;
      white-space: unset;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical
  }
    }

  .styles_nudges__VuGbz {
    margin-bottom: 4px;
  }

  .styles_text__6v3xA {
    margin-bottom: 8px;
  }

  .styles_productImage__gyRNL {
    line-height: 0;
    border-radius: 2px;
    overflow: hidden;
  }

  .styles_productPlaceholder__JFreB {
    background-color: #D7D7D7;
    border-radius: 2px;
    height: 120px;
    width: 120px;
  }

  .styles_productDetails__3KX6o {
    flex: 1;
    min-width: 0;
  }

  .styles_shippingPriceCaption__Go6zg {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    min-width: 0;
    white-space: nowrap;
    margin-bottom: 8px;
  }
}


@layer components {
  .styles_wrapper__xxJIy {
    display: flex;
    gap: 16px;
    width: 100%;
  }

  .styles_buttonWrapper__PHVfF {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;
  }

  .styles_button__Hp3yp {
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 4px;
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
    background: #FFFFFF;
    border-width: 2px;
    border-style: solid;
    border-color: #D7D7D7;
    transition: 0.2s ease all;
  }

    .styles_button__Hp3yp:hover {
      border-color: #262626;
    }

    .styles_button__Hp3yp span {
      display: block;
      color: inherit;
    }

  .styles_buttonSelected__94csr {
    border-color: #262626;
  }

    .styles_buttonSelected__94csr:hover {
      border-color: #262626;
    }

  .styles_buttonRecommended__ABcur {
    border-color: rgba(41, 127, 0, 0.3);
    background: rgba(214, 244, 200, 0.3);
    color: rgba(37, 109, 28, 1);
  }

    .styles_buttonRecommended__ABcur:hover {
      border-color: #266D1C;
    }

  .styles_buttonSelectedRecommended__ISwr7 {
    border-color: #266D1C;
  }

  .styles_recommendationText__MhUvF {
    margin-top: 4px;
    color: #266D1C;
  }
}



@layer components {
  .styles_offerTileMenuWrapper__pJXO_ {
    position: absolute;
    margin: 0;
    right: 16px;
  }

  .styles_offerTileMenuButton__V5k6x {
    background-color: transparent;
    border: none;
    outline: none;
  }
}

@layer components {
  .styles_header__s_ZdH {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .styles_header--large__gEZQX {
    margin-bottom: 16px;
  }

    @media (min-width: calc(576 * 1px)) {

  .styles_header--large__gEZQX {
      margin-bottom: 24px
  }
    }

  .styles_header--small__y66v3 {
    margin-bottom: 8px;
  }

    @media (min-width: calc(576 * 1px)) {

  .styles_header--small__y66v3 {
      margin-bottom: 16px
  }
    }

  .styles_title__jRKML {
    font-size: 1.125rem; /* 18px */
  }
    @media (min-width: calc(576 * 1px)) {

  .styles_title--large__eYcuM {
      font-size: 1.5rem /* 24px */
  }
    }

  .styles_subtitle--large__c1_13 {
    font-size: 1rem; /* 18px */
  }

  .styles_subtitle--small__2i8np {
    font-size: 0.875rem; /* 14px */
  }
}
@layer components {
  .styles_boostingOverlay__iDoFF {
    height: 100%;
    top: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.7);
    z-index: 2;
    width: 100%;
  }

    @media (min-width: calc(768 * 1px)) {
  .styles_boostingOverlay__iDoFF {
        width: unset
  }
    }

  .styles_animationImg__2jVsG {
    width: 30%;
  }

    @media (min-width: calc(768 * 1px)) {

  .styles_animationImg__2jVsG {
        width: 60%
  }
    }
}

@layer components {
  .styles_pill__xan9Y {
    display: inline-flex;
    background-color: #FFFFFF;
    color: #262626;
    border: 1px solid #747474;
    flex-shrink: 0;
    text-decoration: none;
    padding: 8px 16px;
    border-radius: 32px;
    cursor: pointer;
    font-family: var(--font-GtAmericaExtended);
    font-size: 0.875rem;
  }

    .styles_pill__xan9Y:first-of-type {
      margin-left: 16px;
    }

    .styles_pill__xan9Y:hover {
      background-color: rgba(243, 243, 243, 0.6);
    }

    @media (min-width: calc(768 * 1px)) {
      .styles_pill__xan9Y:first-of-type {
        margin-left: 0;
      }
    }

  .styles_pillActive__7PLzd {
    background-color: #262626;
    color: #FFFFFF;
  }

    .styles_pillActive__7PLzd:hover {
      background-color: #747474;
      border-color: #747474;
      color: #FFFFFF;
    }
}

@layer components {
  .styles_productCardRoot__DaYPT {
    position: relative;
  }

  .styles_baseProductImageContainer__4kInl {
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    aspect-ratio: 1 / 1;
  }

    .styles_baseProductImageContainer__4kInl img {
      width: 100%;
    }

    .styles_baseProductImageContainer__4kInl{

    transition: filter 0.3s ease;
  }

  .styles_productImageContainer__withoutHoverOverlay__oJusn {
  }

    .styles_productImageContainer__withoutHoverOverlay__oJusn:hover {
      filter: brightness(0.7);
    }

  .styles_productImageContainer__withHoverOverlay__B5Z12 {
  }

  .styles_overlay__rSQ3Y {
    position: absolute;
    inset: 0;
  }

  .styles_hoverOverlay__6Zs_i {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.3s ease opacity;
    z-index: calc(0 + 1);
  }

    .styles_hoverOverlay__6Zs_i:hover,
    .styles_hoverOverlay__6Zs_i:focus {
      opacity: 1;
    }

    @media (hover: none) {

  .styles_hoverOverlay__6Zs_i {
      display: none
  }
    }
}

@layer components {
  .styles_buttonMinimal__iE3by {
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-family: var(--font-GtAmericaExtended), -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    font-weight: normal;
    background-color: transparent;
    color: #262626;
    padding: 0;
    border: none;
    line-height: 1.25;
  }

  .styles_buttonMinimal__iE3by:hover {
    background: transparent;
    border: none;
    text-decoration: underline;
  }

  .styles_buttonMinimal__iE3by[disabled] {
    background: transparent;
    border: none;
    cursor: not-allowed;
    color: #D7D7D7;
  }
}

@layer components {
  .styles_offerTileContainer__e88sw {
    background-color: #FFFFFF;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }

  .styles_offerTileRow__Mt8qe {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
  }

  .styles_offerTileTextContainer__L8OLs {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    order: 1;
  }

  .styles_offerTileTitle__W3TyB {
    color: #43484D;
    flex-grow: 1;
    font-size: 0.75rem;
    margin-bottom: 4px;
  }

  .styles_purchaseableOfferTitle__Z_UyV {
    color: #266D1C;
  }

  .styles_rejectedOfferTitle__EX_RC {
    color: #E20020;
  }

  .styles_offerTileText__ApMoZ {
    color: #43484D;
    font-size: 0.625rem;
  }

  .styles_purchaseableOfferTileText__12qvR {
    color: #266D1C;
  }

  .styles_offerTilePriceContainer__mnACY {
    display: flex;
    align-items: center;
  }

  .styles_offerTilePrice__lIGn2 {
    color: #262626;
    margin-right: 8px;
    font-size: 0.875rem;
  }

  .styles_offerTileOriginalPrice__5MDSl {
    color: #43484D;
    text-decoration: line-through;
  }

  .styles_offerTileLinkContainer___DMBY {
    --mobileImage: 84px;

    height: var(--mobileImage);
    width: var(--mobileImage);
    order: 2;
  }

  .styles_offerTileButtonsContainer__YBjQN {
    flex-grow: 1;
    display: flex;
    margin-top: 16px;
    gap: 4px;
    order: 3;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
  }

  .styles_offerTileButtonsContainerInline__CTbkr {
    gap: 8px;
    flex-direction: row;
    justify-content: flex-start;
  }

  .styles_offerTileBreakpointStyles__tp_CR {
    --desktopImage: 120px;
  }

    @media (min-width: calc(768 * 1px)) {
      .styles_offerTileBreakpointStyles__tp_CR .styles_offerTileContainer__e88sw {
        padding: 24px;
      }

      .styles_offerTileBreakpointStyles__tp_CR .styles_offerTileTextContainer__L8OLs {
        order: 2;
        margin-left: 24px;
      }

      .styles_offerTileBreakpointStyles__tp_CR .styles_offerTileTitle__W3TyB {
        font-size: 1rem;
      }

      .styles_offerTileBreakpointStyles__tp_CR .styles_offerTilePriceContainer__mnACY {
        margin-bottom: 4px;
      }

      .styles_offerTileBreakpointStyles__tp_CR .styles_offerTileLinkContainer___DMBY {
        height: var(--desktopImage);
        width: var(--desktopImage);
        order: 1;
        margin-top: 0;
      }

      .styles_offerTileBreakpointStyles__tp_CR .styles_offerTileButtonsContainer__YBjQN {
        align-items: flex-end;
        max-width: 300px;
        margin-top: 0;
      }
    }
}

@layer components {
  .styles_scrollableContainer__pl8Q8 {
    overflow-x: scroll;
    overflow-y: hidden;
    margin: 0 -16px;
    padding: 0 16px;
  }

    .styles_scrollableContainer__pl8Q8 ::-webkit-scrollbar {
      display: none;
    }

  .styles_pillList__h_bUd {
    list-style: none;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    max-height: 106px; /* Hide any pills on a third row */
    width: calc(200% + 32px);
  } /* Add 32px to account for the negative margin on the scrollableContainer */

    @media (min-width: calc(768 * 1px)) {

  .styles_pillList__h_bUd {
      max-height: 114px; /* Hide any pills on a third row */
      width: 100%;
      gap: 16px
  }
    }

  .styles_pill__7bNtT {
    appearance: none;
    font-size: 0.875rem;
    background: #FFFFFF;
    border: 1px solid #747474;
    margin: 0;
    line-height: 1;
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    padding: 16px 32px;
    color: #262626;
    font-family: var(--font-GtAmericaExtended), -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    transition: background 0.2s ease, box-shadow 0.2s ease;
  }

    .styles_pill__7bNtT:hover {
      background: rgba(243, 243, 243, 0.376);
    }

    .styles_pill__7bNtT:focus {
      box-shadow: inset 0 0 0 4px #FFDA0A;

      /* Visible in Windows high-contrast themes: https://stackoverflow.com/a/52616313 */
      outline: 4px dotted transparent;
    }
}

@layer components {
  .styles_heading__Pmns0 {
    margin-bottom: 8px;
  }

  .styles_headingContainer__m_a7D {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .styles_sellingItemImage__vyY3R {
    min-height: auto;
    min-width: auto;
  }

  .styles_soldOutImageContainer__3_GjT {
    position: relative;
  }
}

@layer components {
  .styles_row__lSyMh {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #D7D7D7;
  }

  .styles_itemDetails__sR_gk {
    display: flex;
    margin-bottom: 16px;
  }

  .styles_image__lrV2y {
    width: 96px;
    margin-right: 8px;
  }

  .styles_description__spxQG {
    display: flex;
    flex-direction: column;
  }

  .styles_productDescription__TlxpJ {
    word-break: break-all;
    max-height: calc(1.5 * 2 * 0.875rem);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    margin-bottom: 8px;
  }

  .styles_metadata__PvL_6 {
    display: flex;
    align-items: center;
  }

  .styles_dotDivider__g2rw_ {
    content: '';
    display: block;
    border-radius: 50%;
    height: 4px;
    width: 4px;
    margin: 0 4px;
    background: #747474;
  }

  .styles_fullPriceDiscounted__Fmrao {
    text-decoration: line-through;
    margin-right: 4px;
  }

  .styles_boostingFeeRow__JzXXp {
    display: flex;
    justify-content: space-between;
  }

  .styles_boostingFee__Z44uT {
    color: #262626;
  }
}

@layer components {
  .styles_container__Gnx6X {
    width: 100%;
    height: calc(100vh - 92px);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }

    @media (min-width: calc(768 * 1px)) {
  .styles_container__Gnx6X {
      width: 400px;
      overflow: unset
  }
    }

  .styles_items__cwvMf {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    margin-bottom: 24px;
  }

    @media (min-width: calc(576 * 1px)) {

  .styles_items__cwvMf {
      flex: 1;
      margin: 0;
      overflow-y: scroll
  }
    }

  .styles_helpLink__4NBNS {
    display: inline-block;
    color: #2960AF;
    font-weight: bold;
    text-decoration: none;
    font-size: 0.75rem;
  }

  .styles_description__uknzx {
    color: #262626;
  }

  .styles_actions__GBuWN {
    padding: 0 24px 24px;
    text-align: center;
  }

  .styles_confirmAndBoostButton__y6Zxi {
    margin: 16px 0;
    width: 100%;
  }

  .styles_errorMessage__iWxMu {
    color: #E20020;
    margin-bottom: 8px;
  }

  .styles_termsAndConditions__xaEbc {
    color: #2960AF;
    margin-bottom: 16px;
  }
}

@layer components {
  .styles_paymentIcon__iaRVx {
    flex-shrink: 0;
    margin-right: 16px;
  }
}

@layer components {
  .sharedStyles_searchBarContainer__OKXN8 {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    position: relative;
    z-index: calc(0 + 1);
  }

    @media (max-width: calc((768 - 1) * 1px)) {
  .sharedStyles_searchBarContainer__OKXN8 {
      display: block;
      margin-left: 0
  }
    }
    @media (max-width: calc((768 - 1) * 1px)) {

  .sharedStyles_searchBarContainer--desktopOnly__5Riu_ {
      display: none
  }
    }

  .sharedStyles_wrapper__wzOE3 {
    position: relative;
    margin: auto;
    display: flex;
    width: calc(42vw);
    max-width: 704px;
  }

    @media (max-width: calc((768 - 1) * 1px)) {

  .sharedStyles_wrapper__wzOE3 {
      width: calc(100vw - 32px)
  }
    }
    @media (max-width: calc((1024 - 1) * 1px)) {

  .sharedStyles_wrapper--shouldShrink__rPdRN {
      width: calc(34vw)
  }
    }

  .sharedStyles_searchBarIcon__RNIKm {
    position: absolute;
    vertical-align: middle;
    display: block;
    height: 100%;
    left: 16px;
    z-index: calc(calc(0 + 1) + 1);
    color: #666666;
  }

    @media (max-width: calc((768 - 1) * 1px)) {

  .sharedStyles_searchBarIcon__RNIKm {
      left: 8px
  }
    }

  .sharedStyles_searchBarIcon--focused__TZqxP {
    color: #262626;
  }

    @media (max-width: calc((768 - 1) * 1px)) {

  .sharedStyles_searchBarIcon--focused__TZqxP {
      color: #43484D
  }
    }

  .sharedStyles_input__GTvKf {
    height: 40px;
    border-radius: 4px;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border: 2px solid #262626;
    width: 100%;
    font-family: var(--font-GtAmericaExtended), -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    padding: 8px 8px 0;
    box-shadow: none;
    appearance: none;
  }

    .sharedStyles_input__GTvKf:focus {
      outline: none;
    }

    .sharedStyles_input__GTvKf ::placeholder {
      font-size: 0.875rem;
      color: #43484D;
    }

    /* Needed to override a media query in Input */
    @media screen and (min-width: 0) {

  .sharedStyles_input__GTvKf {
      padding: 0 8px 0 40px
  }
    }

    @media (max-width: calc((576 - 1) * 1px)) {

  .sharedStyles_input__GTvKf {
      padding: 0 8px 0 40px
  }
    }

    @media (min-width: calc(768 * 1px)) {

  .sharedStyles_input__GTvKf {
      border-radius: 30px;
      background: #F3F3F3
  }
    }

  .sharedStyles_commonButtonStyles__VBnW1 {
    position: absolute;
    display: flex;
    align-items: center;
  }

    .sharedStyles_commonButtonStyles__VBnW1 :disabled {
      background-color: transparent;
      border-color: transparent;
    }

    .sharedStyles_commonButtonStyles__VBnW1:hover {
      cursor: pointer;
    }

    .sharedStyles_commonButtonStyles__VBnW1:hover,
    .sharedStyles_commonButtonStyles__VBnW1:focus {
      outline: 0;
      box-shadow: none;
    }

      .sharedStyles_commonButtonStyles__VBnW1:hover :disabled, .sharedStyles_commonButtonStyles__VBnW1:focus :disabled {
        background-color: transparent;
        border-color: transparent;
      }

  .sharedStyles_clearButton__ckxc5 {
    top: 10px;
    right: 48px;
    height: 20px;
    justify-content: center;
    padding: 0 0 1px 0;
    min-width: 48px;
    background-color: #747474;
    border: none;
    color: #FFFFFF;
    font-size: 0.5625rem;
    text-transform: uppercase;
    border-radius: 28px;
    font-weight: normal;
    z-index: calc(calc(0 + 1) + 1);
    font-family: var(--font-GtAmericaExtended), -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    transition: all 0.2s ease;
  }

    .sharedStyles_clearButton__ckxc5:hover,
    .sharedStyles_clearButton__ckxc5:focus {
      background-color: hsl(0deg 0% calc(45.5% + 20%));
      color: #FFFFFF;
      border: none;
    }

    @media (max-width: calc((768 - 1) * 1px)) {

  .sharedStyles_clearButton__ckxc5 {
      top: 8px;
      height: 24px;
      font-family: inherit;
      transition: none
  }
    }

  .sharedStyles_submitButton__vv_vZ {
    top: 0;
    right: 0;
    height: 40px;
    padding: 0 8px;
    width: 40px;
    background-color: transparent;
    border-color: transparent;
    color: #262626;
    z-index: calc(calc(0 + 1) + 1);
    border-radius: 0 30px 30px 0;
    transition: all 0.2s ease;
  }

    .sharedStyles_submitButton__vv_vZ:hover,
    .sharedStyles_submitButton__vv_vZ:focus {
      background-color: #3d37bd;
      color: #FFFFFF;
      border-color: transparent;
    }

    @media (max-width: calc((768 - 1) * 1px)) {

  .sharedStyles_submitButton__vv_vZ {
      border-radius: 0;
      transition: none
  }
    }

  .sharedStyles_searchBarButtons__HCGDW {
    display: none;
  }

    @media (min-width: calc(576 * 1px)) {

  .sharedStyles_searchBarButtons__HCGDW {
      display: block
  }
    }
}

@layer components {
    /* Needed to override a media query in Input */
    @media screen and (min-width: 0) {
  .styles_input--withValue__pKLz3 {
      padding: 0 104px 0 40px
  }
    }

  .styles_animatedInputLabelStyles__BKVJu {
    overflow: hidden;
    white-space: nowrap;
    max-width: max-content;
    width: 100%;
  }

  .styles_blinkCaretAnimation____I17 {
    position: relative;
  }

    .styles_blinkCaretAnimation____I17::after {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      bottom: 2px;
      right: 0;
      width: 1px;
      background: #262626;
      animation: 1000ms styles_blinkCaret__Sxrfb step-end infinite;
    }

  .styles_initialLabelText__1_2AH {
    animation: 400ms styles_typingOut__cPI7Z forwards;
    animation-delay: 3000ms;
  }

    .styles_initialLabelText__1_2AH::after {
      display: none;
    }
    .styles_initialLabelText--caretVisible__CzOSE::after {
      display: block;
    }

  .styles_rotatingLabelText__G0zpY {
    animation: 4000ms styles_typingInOut__oaLmt infinite;
    display: none;
  }

    .styles_rotatingLabelText__G0zpY::after {
      display: none;
    }

  .styles_rotatingLabelText--visible__12XI0 {
    display: block;
  }
    .styles_rotatingLabelText--caretVisible__Mo_tr::after {
      display: block;
    }

  .styles_labelText__bGazD {
  }

  .styles_searchBarInputWrapper__mOd1N {
    position: relative;
    overflow: hidden;
    flex: 1;
    z-index: calc(0 + 1);
  }

  @keyframes styles_typingOut__cPI7Z {
    0% {
      width: 100%;
    }

    100% {
      width: 0;
    }
  }

  @keyframes styles_typingInOut__oaLmt {
    0% {
      width: 0;
    }

    20% {
      width: 100%;
    }

    70% {
      width: 100%;
    }

    80%,
    100% {
      width: 0;
    }
  }

  @keyframes styles_blinkCaret__Sxrfb {
    0%,
    100% {
      opacity: 0%;
    }

    50% {
      opacity: 100%;
    }
  }
    @media (min-width: calc(768 * 1px)) {

  .styles_input--suggestionsVisible___zEcG {
      background: #FFFFFF;
      border-color: #FFFFFF
  }
    }
}

@layer overrides {
  .styles_inputLabel__xCOOS {
    display: grid;
    grid-template-columns: max-content auto;
    gap: 4px;
    align-items: center;
    position: absolute;
    height: 100%;
    left: 42px;
    pointer-events: none;
  }
}

@layer components {
  .styles_price__H8qdh {
    display: inline-block;
  }

  .styles_discountedFullPrice__JTi1d {
    text-decoration: line-through;
  }
    .styles_discountWrapperp__5BRio:nth-child(2) {
      padding: 0 4px;
    }
}

@layer components {
  .styles_pillsContainer__IunXV {
    display: flex;
    overflow-x: auto;
    margin-left: calc(-1 * 16px);
    padding: 16px;
    width: calc(100% + 32px);
    gap: 8px;
  }

    .styles_pillsContainer__IunXV button {
      padding: 12px;
    }

      .styles_pillsContainer__IunXV button:first-of-type {
        margin-left: 0;
      }

    @media (min-width: calc(768 * 1px)) {
  .styles_pillsContainer__IunXV {
      width: unset;
      margin-left: 0;
      padding-right: 0
  }
    }

  .styles_link__Iq6nO {
    white-space: nowrap;
  } /* Prevent text from wrapping to the next line */

    .styles_link__Iq6nO:first-of-type {
      margin-left: 16px;
    }
}

@layer components {
  .styles_searchBarWrapper__Qhboz {
    display: block;
  }

    @media (min-width: calc(768 * 1px)) {
  .styles_searchBarWrapper__Qhboz {
      display: none
  }
    }

  .styles_searchBarWithMargin__6WW2g {
    margin-bottom: 16px;
  }
}

@layer components {
  .styles_seeMoreText__eLt1c {
    color: #2960AF;
  }

    .styles_seeMoreText__eLt1c:hover {
      text-decoration: underline;
    }

  .styles_productListItem__xilhW {
    list-style: none;
  }
}

@layer components {
  .styles_similarItemsSashWrapper__f5X8c {
    margin: 8px 0 16px;
  }

    @media (min-width: calc(576 * 1px)) {
  .styles_similarItemsSashWrapper__f5X8c {
      margin: 32px 0 24px;
      min-height: 180px
  }
    }

    .styles_similarItemsSashWrapper__f5X8c li {
      margin-bottom: 0;
    }

  .styles_scrollableItemRow__4vxmL {
    grid-template-columns: repeat(8, 1fr);
  }

    @media breakpointMdUp {

  .styles_scrollableItemRow__4vxmL {
      grid-template-columns: repeat(6, 1fr)
  }
    }
}

@layer components {
  .styles_wrapper__ZIeT_ {
    display: grid;
    grid-template-columns: 32px 1fr;
    gap: 16px;
    align-items: center;
    padding: 16px;
    background: #F3F3F3;
    border-radius: 8px;
  }

  .styles_clothesIcon__KsvaY {
    margin: 0 auto;
  }

  .styles_seeSimilarItemsText__UFwNX {
    color: #3d37bd;
    text-decoration: none;
  }

  .styles_seeSimilarItemsText__UFwNX:hover,
  .styles_seeSimilarItemsText__UFwNX:focus {
    text-decoration: underline;
  }
}

@layer components {
  .styles_attribute__QC7gC {
    display: inline-block;
    color: #43484D;
  }

  .styles_linkAttribute__P1lja {
    text-decoration: underline;
  }

  .styles_attributesContainer___CJRR {
    margin: 0 0 16px;
  }
      .styles_attributesContainer___CJRR .styles_attribute__QC7gC::after {
        content: ' • ';
        white-space: pre;
      }

      .styles_attributesContainer___CJRR .styles_attribute__QC7gC:last-child::after {
        content: none;
      }

      .styles_attributesContainer___CJRR .styles_attribute__QC7gC:is(a) {
        cursor: pointer;
        text-decoration: underline;
      }
}

@layer components {
  .styles_container__cXTd4 {
    margin-bottom: 32px;
  }

    @media (max-width: calc((768 - 1) * 1px)) {
  .styles_container__cXTd4 {
      display: none
  }
    }

  .styles_headingRow__CT_FB {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .styles_seeMoreButton__dp6JH {
    background: none;
    cursor: pointer;
    border: none;
    padding: 0;
    color: #2960AF;
  }

  .styles_feedbackList__2Ub3X {
    list-style: none;
  }

    .styles_feedbackList__2Ub3X li:not(:last-child) {
      margin-bottom: 16px;
    }

  .styles_placeholderList__tZCzV {
    list-style: none;
  }
    
    .styles_placeholderList__tZCzV li {
      margin-bottom: 16px;
    }

    .styles_placeholderList__tZCzV li:last-child {
      margin-bottom: 0;
    }
}

@layer components {
  .styles_authorRow__WNrIS {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .styles_author__5DLa_ {
    color: #999999;
    margin-left: 8px;
  }

  .styles_date__JXXwX {
    flex: 1;
    color: #999999;
    margin-left: 8px;
    padding-left: 8px;
    border-left: 1px solid #D7D7D7;
  }

  .styles_reviewRow__DT1I_ {
    display: grid;
    grid-template-columns: 54px 1fr;
    gap: 16px;
  }

  .styles_imgWrapper__5R020 {
    display: inherit;
    overflow: hidden;
    border-radius: 4px;
  }

  .styles_reviewContent__dBv_J {
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: #43484D;
  }
}

@layer components {
  .styles_scrollableRow__P8iUE {
    /* Ensures that there's spacing at the end of a scrolled row; */
    padding: 0 var(--paddingRight);

    /* Escapes the margin of the page when scrolling the carousel */
    margin: 0 var(--marginRight);
    display: grid;
    grid-template-columns: repeat(var(--itemsPerRow), 1fr);
    gap: 8px;
    overflow-x: scroll;
    list-style: none;
  }

    .styles_scrollableRow__P8iUE::-webkit-scrollbar {
      display: none;
    }

    .styles_scrollableRow__P8iUE li,
    .styles_scrollableRow__P8iUE a {
      min-width: var(--minItemWidth);
    }

    @media (min-width: calc(768 * 1px)) {
  .styles_scrollableRow__P8iUE {
      gap: 16px
  }
    }
}

@layer components {
  .styles_productAttributesContainer__1UjMl {
    margin: 8px 4px 0;
  }

  .styles_videoIcon__UK3z3 {
    position: absolute;
    bottom: 8px;
    left: 8px;
    color: #FFFFFF;
  }
}

@layer components {
  .styles_marginTop__Pgfjp {
    margin-top: 32px;
  }

    @media (max-width: calc((576 - 1) * 1px)) {
  .styles_marginTop__Pgfjp {
      margin-top: 24px
  }
    }
}

@layer component {
  .styles_container__IlE6f {
    display: grid;
    grid-template-columns: minmax(368px, 40%) 60%;
    min-height: 224px;
    border-radius: 4px;
    overflow: hidden;
    height: 240px;
  }

    @media (max-width: calc((768 - 1) * 1px)) {
  .styles_container__IlE6f {
      display: flex;
      flex-direction: column-reverse;
      height: 100%
  }
    }

  .styles_contentWrapper__K6H1Q {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #43484D;
    padding: 24px 24px 24px 48px;
  }

    @media (max-width: calc((768 - 1) * 1px)) {

  .styles_contentWrapper__K6H1Q {
      flex: 1;
      text-align: center;
      justify-content: flex-start;
      padding: 16px
  }
    }

  .styles_heading__X0pIn {
    margin-bottom: 4px;
    color: #FFFFFF;
  }

    @media (max-width: calc((768 - 1) * 1px)) {

  .styles_heading__X0pIn {
      font-size: 1.125rem
  }
    }

  .styles_bodyParagraph__O_BJw {
    margin-bottom: 24px;
    color: #FFFFFF;
  }

    .styles_bodyParagraph__O_BJw a {
      white-space: nowrap;
      color: #FFFFFF;
      text-decoration: underline;
    }

      .styles_bodyParagraph__O_BJw a:hover {
        opacity: 0.5;
      }

    @media (max-width: calc((768 - 1) * 1px)) {

  .styles_bodyParagraph__O_BJw {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 16px
  }
    }
    .styles_ctaWrapper__1Ymwr a {
      display: inline-flex;
    }

  .styles_bannerImage__JLZdQ {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .styles_bannerButton__JZoxT {
    border-width: 0;
  }
}

@layer components {
  .styles_container__toxll {
    display: flex;
    max-height: 485px;
    overflow: hidden;
    margin-bottom: 24px;
  }

    @media (min-width: calc(576 * 1px)) {
  .styles_container__toxll {
      margin-bottom: 32px
  }
    }

    @media (max-width: calc((768 - 1) * 1px)) {
  .styles_container__toxll {
      flex-direction: column;
      max-height: none
  }
    }

  .styles_title__7Rq8_ {
    font-size: 3rem;
    color: #FFFFFF;
    text-shadow: rgba(0, 0, 0, 0.4) 0 0 10px;
    font-family: var(--font-GtAmericaExpanded), -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
  }

    @media (min-width: calc(768 * 1px)) and (max-width: calc((1024 - 1) * 1px)) {

  .styles_title__7Rq8_ {
      font-size: 2rem
  }
    }

    @media (max-width: calc((576 - 1) * 1px)) {

  .styles_title__7Rq8_ {
      font-size: 1.5rem
  }
    }

  .styles_link__NviBK {
    position: relative;
    width: 100%;
  }

  .styles_image__kOTgF {
    display: block;
    width: 100%;
  }

  .styles_titleButtonContainer__sPLdu {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    bottom: 25%;
    gap: 8px;
    left: 50%;
    transform: translateX(-50%);
  }

  .styles_button__Q3PGY {
    background-color: #FFFFFF;
    color: #262626;
    border-color: #FFFFFF;
    padding: 10px 14px;
    border-radius: 4px;
  }

    .styles_button__Q3PGY:hover {
      background-color: #FFFFFF;
      border-color: #FFFFFF;
    }
}

@layer components {
  .styles_link___M_mL {
    text-decoration: none;
  }

  .styles_imgContainer__d7p7E {
    position: relative;
    font-size: 0;
    background-color: #F3F3F3;
    border-radius: 4px;
  }

    .styles_imgContainer__d7p7E img {
      border-radius: 4px;
      width: 100%;
    }

    .styles_imgContainer__d7p7E:hover::after {
      content: '';
      position: absolute;
      display: block;
      inset: 0;
      background-color: rgba(38, 38, 38, 0.3);
      border-radius: 4px;
    }

  .styles_textContainer__5SBSI {
    margin-top: 8px;
  }

    @media (min-width: calc(768 * 1px)) {

  .styles_textContainer__5SBSI {
      text-align: center
  }
    }

  .styles_title__4RbXk {
    font-size: 1rem;
  }

  .styles_text__HZAYQ {
    font-size: 0.875rem;
    color: #747474;
  }
}

@layer components {
  .styles_container__InF6H {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-wrap: balance;
  }

  .styles_title__SXIAU {
    font-size: 2rem;
    align-self: center;
    font-family: var(--font-GtAmericaExpanded), -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    margin-bottom: 16px;
  }

    @media (max-width: calc((576 - 1) * 1px)) {

  .styles_title__SXIAU {
      font-size: 1.125rem
  }
    }

  .styles_link__ruMyG {
    text-decoration: none;
  }

  .styles_ctaButton__19X1p {
    font-family: var(--font-GtAmericaExpanded), -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    border-radius: 4px;
  }
}

@layer components {
  .styles_searchTerm__l33_Z {
    margin-left: 8px;
    font-weight: normal;
  }

    @media (max-width: calc((576 - 1) * 1px)) {
  .styles_searchTerm__l33_Z {
      margin-left: 0;
      display: grid
  }
    }

  .styles_seeMoreText___D2Ca {
    color: #2960AF;
  }

    .styles_seeMoreText___D2Ca:hover {
      text-decoration: underline;
    }

  .styles_productListItem__S6vYi {
    list-style: none;
  }
}

@layer components {
  .styles_seeMoreText__Ybw2f {
    color: #2960AF;
  }

  .styles_productListItem__oh_KZ {
    list-style: none;
  }
}

@layer components {
  .styles_modules__XyVRv {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

    @media (min-width: calc(576 * 1px)) {
  .styles_modules__XyVRv {
      gap: 40px
  }
    }
}

@layer components {
  .styles_scrollableItemRow__LNtYj {
    grid-template-columns: repeat(4, 1fr);
  }

    @media (min-width: calc(768 * 1px)) {
  .styles_scrollableItemRow__LNtYj {
      grid-template-columns: repeat(6, 1fr)
  }
    }

  .styles_attributesSkeleton__L2aSf {
    display: none;
  }

    @media (min-width: calc(768 * 1px)) {

  .styles_attributesSkeleton__L2aSf {
      display: block
  }
    }
    .styles_segments__PPVPU .styles_segment__X71ZR {
      margin-bottom: 16px;
    }

      @media (min-width: calc(768 * 1px)) {
    .styles_segments__PPVPU .styles_segment__X71ZR {
        margin-bottom: 32px
    }
      }

    .styles_segments__PPVPU .styles_segment__X71ZR:last-child {
      margin-bottom: 0;
    }

  .styles_seeMoreText__JqLCY {
    color: #2960AF;
  }

  .styles_modalProductListContainer__QXWnV {
    padding: 0 32px 32px;
  }

    .styles_modalProductListContainer__QXWnV li {
      list-style: none;
    }
}

@layer components {
    .styles_productAttributesContainer__h02Bs {
        display: flex;
        justify-content: space-between;
        margin: 8px 8px 0;
    }

    .styles_sizeAttributeText__r9QJj {
        color: #43484D;
    }

    .styles_boostedTag__Y18ko {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: calc(calc(0 + 1) + 1);
        color: #262626;
        background: #FFFFFF;
        padding: 0 4px 1px;
        border-radius: 4px;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
    }

        @media (min-width: calc(768 * 1px)) {

    .styles_boostedTag__Y18ko {
            position: relative;
            top: unset;
            right: unset;
            background: none;
            padding: 0;
            border-radius: 0;
            box-shadow: none;

            /* caption1 styles copied from web-ui-kit */
            font-size: 0.75rem;
            line-height: normal;
            color: #747474
    }
        }

    .styles_gradientOverlay__AmIq9 {
        height: 100%;
        background: linear-gradient(
            1.26deg,
            rgba(0, 0, 0, 0.3) -9.48%,
            rgba(0, 0, 0, 0) 95.88%
        );
    }

    .styles_translucentHoverOverlay__5U6sW {
        height: 100%;
        background: #262626;
        opacity: 0.3;
    }

    .styles_likeButton__cG0QM {
        position: absolute;
        bottom: 4px;
        right: 8px;
        z-index: calc(calc(0 + 1) + 1);
    }

        @media (min-width: calc(768 * 1px)) {

    .styles_likeButton__cG0QM {
            bottom: 8px
    }
        }

    .styles_likeButtonUnfilled__6wOKL {
        color: #FFFFFF;
    }

    .styles_likedButtonFilled__dS9CL {
        animation: styles_heartbeat__5mhEm 0.5s linear;
        color: #FFFFFF;
    }
}
@layer components {
    .styles_emptyListTitleContainer__Eaow5 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .styles_emptyListTitle__EPTkD {
        font-size: 1.5rem;
    }

    .styles_emptyListBodyText__NW_n2 {
        margin-top: 8px;
    }

    .styles_emptyListSubHeading__zYH1j {
        margin-top: 32px;
    }

    .styles_emptyProductList__khttJ {
        display: grid;
        gap: 8px;
        grid-template-columns: repeat(2, 1fr);
        margin-top: 32px;
    }

        @media (min-width: calc(576 * 1px)) {

    .styles_emptyProductList__khttJ {
            grid-template-columns: repeat(3, 1fr)
    }
        }

        @media (min-width: calc(768 * 1px)) {

    .styles_emptyProductList__khttJ {
            grid-template-columns: repeat(6, 1fr);
            gap: 16px
    }
        }

    .styles_listItem__nUwIB {
        list-style-type: none;
    }

    .styles_loaderWrapper__U92yU {
        padding-top: 32px;
        padding-bottom: 32px;
      }
}
@layer components {
  .styles_listItem__Uv9lb {
    list-style-type: none;
  }

  .styles_productGrid__Cpzyf {
    position: relative;
    z-index: 0;
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);
  }

    @media (min-width: calc(576 * 1px)) {

  .styles_productGrid__Cpzyf {
      grid-template-columns: repeat(3, 1fr)
  }
    }

    @media (min-width: calc(768 * 1px)) {

  .styles_productGrid__Cpzyf {
      grid-template-columns: repeat(4, 1fr);
      gap: 16px
  }
    }

  .styles_loaderWrapper__RDUnD {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

@layer components {
  .styles_breadcrumbsContainer__G9QE8 {
    margin-bottom: 16px;
    width: 100%;
  }

    .styles_breadcrumbsContainer__G9QE8 ::-webkit-scrollbar {
      display: none;
    }

  .styles_breadcrumbsScrollableContainer__5uzAy {
    display: flex;
    flex-direction: row-reverse;
    overflow: auto;
  }

  .styles_breadcrumbsList__N_6Nm {
    display: flex;
    flex-grow: 1;
    flex-wrap: nowrap;
    list-style: none;
  }

  .styles_breadcrumbItem__rSDvF {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-right: 4px;
  }
    .styles_breadcrumbLink__N3ENO:focus {
      box-shadow: inset 0 0 0 2px #FFDA0A;

      /* Visible in Windows high-contrast themes: https://stackoverflow.com/a/52616313 */
      outline: 4px dotted transparent;
    }

  .styles_breadcrumbLinkText__OtH96 {
    color: #747474;
  }

    .styles_breadcrumbLinkText__OtH96:hover {
      text-decoration: underline;
    }

  .styles_breadcrumbLabel__D4V6w {
    margin-right: 4px;
  }

  .styles_breadcrumbActiveLabel__sUvLk {
    color: #262626;
  }
}

@layer components {
  .styles_container__Mwz_b {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 1;
  }

    @media (max-width: calc((768 - 1) * 1px)) {
  .styles_container__Mwz_b {
      top: auto;
      bottom: 8px;
      right: 8px;
      flex-direction: column-reverse
  }
    }

  .styles_buttonInteractionWrapper__kWd2F {
    position: relative;
    width: 44px;
    height: 44px;
    background-color: #FFFFFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 8px rgba(116, 116, 116, 0.3);
    overflow: hidden;
  }

    .styles_buttonInteractionWrapper__kWd2F button {
      margin: 0;

      /* Makes the button element expand to fill the whole area of the circular div */
      padding: 24px;
    }

    .styles_buttonInteractionWrapper__kWd2F svg {
      width: 24px;
    }

    .styles_buttonInteractionWrapper__kWd2F > * {
      opacity: 1;
      transition: 0.3s ease opacity;
    }

    .styles_buttonInteractionWrapper__kWd2F p {
      position: absolute;
      bottom: 2px;
      text-align: center;
    }
      .styles_buttonInteractionWrapper__kWd2F:has(p) button {
        position: relative;
        top: -3px;
      }

    .styles_buttonInteractionWrapper__kWd2F:focus-within,
    .styles_buttonInteractionWrapper__kWd2F:active {
      box-shadow: 0 0 0 2px #FFDA0A;
      outline-color: transparent;
      outline-width: 2px;
    }
      .styles_buttonInteractionWrapper__kWd2F:hover > * {
        opacity: 0.6;
      }

  .styles_interactionButton__ZT1TU {
    cursor: pointer;
    font-size: 1rem;
    font-family: var(--font-GtAmericaExtended), -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    font-weight: normal;
    background-color: transparent;
    color: #262626;
    border: none;
    line-height: 1.25;
    padding: 0;
    margin-right: 8px;
    display: inline-flex;
  }

    .styles_interactionButton__ZT1TU:hover {
      background: transparent;
      border: none;
      text-decoration: underline;
    }

    .styles_interactionButton__ZT1TU:disabled {
      border: none;
      cursor: not-allowed;
      background-color: #FFFFFF;
    }

    @media (max-width: calc((768 - 1) * 1px)) {

  .styles_interactionButton__ZT1TU {
      margin-right: 4px
  }
    }
}

@layer components {
  @keyframes styles_likeHeartbeat__U4j1m {
    0%,
    100% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.2);
    }
  }

  .styles_likeIcon__mufHp {
    height: 28px;
    width: 28px;
  }

  .styles_emptyLikeIcon__WvUQs {
    color: #262626;
  }

  .styles_filledLikeIcon__o838I {
    color: #E20020;
  }

  .styles_animatedLike__R_vMB {
    animation: styles_likeHeartbeat__U4j1m 0.5s linear;
  }

  .styles_whiteLikeButton__Gx9Hs {
    color: #FFFFFF;
  }
  
}

@layer components {
  .styles_saveIcon__DcHSv {
    height: 28px;
    width: 28px;
    margin: auto;
  }
}

@layer components {
  .styles_stars__Ca377 {
    color: #E20020;
  }
}

@layer component {
  .styles_ctaWrapper__9foFt {
    margin: 24px 0 16px;
  }

    .styles_ctaWrapper__9foFt a {
      text-decoration: none;
      color: #3d37bd;
    }

      .styles_ctaWrapper__9foFt a:hover {
        text-decoration: underline;
      }
}

@layer components {
  .styles_activeFiltersContainer__mBePW {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 0 24px;
    width: 100%;
  }

    @media (min-width: calc(1024 * 1px)) {
  .styles_activeFiltersContainer__mBePW {
      padding: 0
  }
    }

  .styles_resetAllButton__r7uEM {
    display: none;
  }

    @media (min-width: calc(1024 * 1px)) {

  .styles_resetAllButton__r7uEM {
      margin-left: 8px;
      display: block;
      font-size: 0.75rem;
      color: #2960AF
  }
    }
}

@layer components {
  .styles_container__t3fcT {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

    @media (min-width: calc(768 * 1px)) {
  .styles_container__t3fcT {
      gap: 24px
  }
    }
}

@layer components {
  .styles_actionWrapper__hjha1 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 16px;
    margin-bottom: 0;
  }

    @media (min-width: calc(1024 * 1px)) {
  .styles_actionWrapper__hjha1 {
      align-items: center;
      margin-bottom: 0
  }
    }

  .styles_filterButton__1mthE {
    padding: 8px 16px;
    display: none;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
    background-color: #F3F3F3;
    border: 1px solid #acacac;
    border-radius: 4px;
    font-family: var(--font-GtAmericaExtended), -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    cursor: pointer;
  }

    @media (min-width: calc(1024 * 1px)) {

  .styles_filterButton__1mthE {
      display: flex
  }
    }

  .styles_mobileFilterButton__nPDSt {
    display: flex;
    padding: 8px 10px;
    background-color: #FFFFFF;
  }

    @media (min-width: calc(1024 * 1px)) {

  .styles_mobileFilterButton__nPDSt {
      display: none
  }
    }

  .styles_activeFilterCount__jG__i {
    border-radius: 50%;
    background-color: #262626;
    height: 20px;
    width: 20px;
    color: #FFFFFF;
  }

  .styles_notificationWrapper__Wc6dE {
    position: relative;
  }

  .styles_containerGrow__xDfbV {
    display: flex;
    align-content: space-between;
  }

  .styles_filterWrapper___uC17 {
    display: none;
    padding-top: 16px;
    background-color: #FFFFFF;
  }

    @media (min-width: calc(768 * 1px)) {

  .styles_filterWrapper___uC17 {
      display: block
  }
    }

    @media (min-width: calc(1024 * 1px)) {

  .styles_filterWrapper___uC17 {
      padding-top: 16px
  }
    }

  .styles_activeFilterDesktopContainer__x_nx_ {
    padding-top: 16px;
    display: none;
  }

    @media (min-width: calc(1024 * 1px)) {

  .styles_activeFilterDesktopContainer__x_nx_ {
      display: inline-block
  }
    }

  .styles_activeFilterMobileContainer__AbV3U {
    display: flex;
  }

    @media (min-width: calc(1024 * 1px)) {

  .styles_activeFilterMobileContainer__AbV3U {
      display: none
  }
    }

  .styles_desktopSellingFilters__jA2Zl {
    display: none;
  }

    @media (min-width: calc(576 * 1px)) {

  .styles_desktopSellingFilters__jA2Zl {
      display: block
  }
    }

  .styles_mobileSellingFilters__0RowX {
    display: block;
  }

    @media (min-width: calc(576 * 1px)) {

  .styles_mobileSellingFilters__0RowX {
      display: none
  }
    }
}

@layer components {
  .styles_wrapper__EMzxz {
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
    padding: 0 16px;
  }

  .styles_wrapper--narrow__OdeGy {
    max-width: 992px; /** 960px + 16px padding either side */
  }

  .styles_wrapper--full__iUX8Q {
    max-width: none;
    padding: 0 32px;
  }

    @media (max-width: calc((576 - 1) * 1px)) {

  .styles_wrapper--full__iUX8Q {
      padding: 0 16px
  }
    }
    @media (min-width: calc(1024 * 1px)) {

  .styles_wrapper--gridMaxWidth__BHuGu {
      padding: 0
  }
    }
}

@layer overrides {
  .styles_feedbackButton__iMmp7 {
    display: block;
    padding: 0;
  }

    @media (max-width: calc((576 - 1) * 1px)) {
  .styles_feedbackButton__iMmp7 {
      /* Touch targets must be 24px large for accessibility */
      height: 24px
  }
    }

    .styles_feedbackButton__iMmp7 span {
      display: flex;
      align-items: center;
      font-weight: normal;
    }

      .styles_feedbackButton__iMmp7 span:hover {
        text-decoration: underline;
        color: #262626;
      }

    .styles_feedbackButton__iMmp7 svg {
      display: flex;
      align-items: center;
      margin-right: 2px;
    }

      .styles_feedbackButton__iMmp7 svg:last-of-type {
        margin-right: 8px;
      }
}

@layer components {
  .styles_container__e_lc6 {
    background-color: #C5E0FF;

    /* Padding approved with designers - 23/08/23 */
    padding: 4px 11px 4px 8px;
    border-radius: 50px;

    /* Color here applies to icon embedded */
    color: #3d37bd;
    display: inline-flex;
    align-items: center;
  }

  .styles_nudgeText__KnLmm {
    margin-left: 8px;
    color: #262626;
  }
}

@layer overrides {
  .styles_searchIcon__OjU_i {
    width: 27px;
    height: 27px;
    cursor: pointer;
    color: #262626;
  }

    .styles_searchIcon__OjU_i:hover {
      color: #747474;
    }

  .styles_searchIcon--isActive__mYE_l {
    color: #2960AF;
  }

    .styles_searchIcon--isActive__mYE_l:hover {
      color: #2960AF;
    }

  .styles_searchButton__V6KYv {
    padding: 0;
    display: inline-flex;
    margin: auto 0;
    min-width: 44px;
    min-height: 44px;
    justify-content: center;
    align-items: center;
  }
}
@layer components {
  .styles_nav__6AJqE {
    display: flex;
    height: 32px;
  }

    @media (max-width: calc((576 - 1) * 1px)) {
  .styles_nav__6AJqE {
      margin: 0 0 0 auto
  }
    }

  .styles_navigationButtons__LPYVE {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    margin-left: -2px;
  }

    @media (max-width: calc((768 - 1) * 1px)) {

  .styles_navigationButtons__LPYVE {
      margin-right: -4px
  }
    }
    @media (max-width: calc((768 - 1) * 1px)) {

  .styles_navigationButtons--loggedIn__c1ZbL {
      margin-right: -2px
  }
    }
}

@layer components {
  .styles_container__tjlq0 {
    padding-bottom: 124px;
  }

  .styles_header__PkATD {
    padding: 16px;
  }

  .styles_headerContent__VSCSI {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .styles_closeButton__NjROc {
    display: flex;
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
  }

  .styles_authButtons__8_3tc {
    display: flex;
    gap: 8px;
    margin-top: 16px;
    flex-direction: column;
  }

    .styles_authButtons__8_3tc a {
      text-decoration: none;
      display: block;
      width: 100%;
    }

  .styles_primaryNav__A_rA0 {
    list-style: none;
    border-top: none;
  }

    .styles_primaryNav--showBorderTop__SsXHz {
      border-top: 1px solid #262626;
    }

    .styles_primaryNav__A_rA0 li {
      border-bottom: 1px solid #F3F3F3;
    }

      .styles_primaryNav__A_rA0 li:last-child {
        border-bottom: none;
      }

  .styles_navListItem__LwIH7 {
    padding: 16px;
  }

    .styles_navListItem__LwIH7 a {
      display: inline-block;
      text-decoration: none;
    }

  .styles_navListItemButton__K4ukY {
    padding: 0;
    background: transparent;
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #262626;
  }

  .styles_authenticatedNav__9dIm8 {
    border-bottom: 1px solid #262626;
    border-top: 1px solid #262626;
  }

    .styles_authenticatedNav__9dIm8 li {
      border-bottom: 1px solid #F3F3F3;
    }

      .styles_authenticatedNav__9dIm8 li:last-child {
        border-bottom: none;
      }

    .styles_authenticatedNav__9dIm8 a:first-child {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

  .styles_offerAlertContainer__peezF {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .styles_offerAlertNotificationContainer__ha8bW {
    position: relative;
    display: inline-block;
    right: 0;
    top: 0;
    margin-right: 8px;
    background-color: #E20020;
    width: 8px;
    height: 8px;
    border-radius: 100%;
  }

  .styles_offerAlertText__aDpej {
    color: #E20020;
    font-size: 0.75rem;
  }

  .styles_secondaryNavWrapper__kwIoX {
    padding: 16px;
    border-top: 1px solid #262626;
    border-bottom: 1px solid #262626;
  }

  .styles_getTheAppLink__WIujz {
    display: inline-block;
    text-decoration: none;
    margin-top: 16px;
    text-transform: lowercase;
    color: #262626;
  }

    .styles_getTheAppLink__WIujz::first-letter {
      text-transform: uppercase;
    }

  .styles_footer__INemc {
    padding: 16px;
  }

  .styles_socialLinks__n_2bI {
    display: grid;
    grid-template-columns: repeat(4, 24px);
    gap: 16px;
    margin-bottom: 16px;
  }

  .styles_selectWrapper__C8kxi {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .styles_mobileMenuWrapper__WSmAx {
    position: fixed;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    top: 0;
    left: 0;
    transform: translate3d(-100%, 0, 0);
    transition: transform 250ms ease-in-out;
    bottom: 0;
    z-index: 3;
    width: 344px;
    overflow: auto;
    background: #FFFFFF;
    color: #262626;
  }

    .styles_mobileMenuWrapper--slideIn__6Msbk {
      visibility: visible;
      transform: translate3d(0, 0, 0);
      box-shadow: 8px 0 48px rgba(0, 0, 0, 0.2);
    }

    .styles_mobileMenuWrapper--slideOut__y0TtT {
      transform: translate3d(-100%, 0, 0);
    }

    .styles_mobileMenuWrapper--hidden__b5UqI {
      visibility: hidden;
    }

  .styles_logoutButton__KZLoB {
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    margin-top: 16px;
  }

  .styles_socialLink__iE_bT {
    position: relative;
  }
}

@layer overrides {
  .styles_depopLogo__joY5I {
    width: 90px;
    height: 23px;
  }

  .styles_navListItemLabel__XcHMM {
    color: var(--textColor);
  }
}

@layer components {
  .styles_container___rSjW {
    position: fixed;
    top: 0;
    left: -100%;
    height: 100vh;
    overflow-y: auto;

    /* Needs to be large enough to not be blocked by the url bar on actual mobile devices */
    padding-bottom: 86px;
    width: 344px;
    background: #FFFFFF;
    transition: all 0.4s ease 0s;
    z-index: 4;
    overscroll-behavior: contain;
  }

  .styles_container--open__FEtvR {
    left: 0;
  }

  .styles_header__Z93vL {
    text-align: center;
    padding: 16px;
    width: 100%;
  }

  .styles_actionButtons__GkVyO {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .styles_actionButton__WcmVs {
    display: flex;
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
  }

  .styles_list__a5Ken {
    list-style: none;
    border-bottom: 1px solid #262626;
  }

    .styles_list__a5Ken li:first-child, .styles_list__a5Ken li:last-child {
      border-bottom: none;
    }

  .styles_baseListItem__sX1Rf {
    padding: 16px;
    border-bottom: 1px solid #F3F3F3;
  }

  .styles_popularTilesContainer__yIJCM {
    padding: 16px;
  }
}

@layer overrides {
  .styles_anchor__MYJlU {
    width: 100%;
    font-family: var(--font-GtAmericaExtended), -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    color: var(--textColor, #262626);
    font-weight: normal;
    font-size: 100%;
  }

  .styles_anchor--active__TYeUQ {
    font-weight: bold;
  }
}

@layer components {
    .styles_desktopImage__bLu91 {
        display: none;
    }

        @media (min-width: calc(768 * 1px)) {
    .styles_desktopImage__bLu91 {
            display: flex;
            flex-grow: 1
    }
        }

        .styles_desktopImage__bLu91 img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }

    .styles_mobileImage__SULTh {
        display: block;
        width: 100%;
    }

        .styles_mobileImage__SULTh img {
            object-fit: cover;
            width: 100%;
        }

        @media (min-width: calc(768 * 1px)) {

    .styles_mobileImage__SULTh {
            display: none
    }
        }

    .styles_featuredImageContainer__543c5 {
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 16px;
    }

        .styles_featuredImageContainer__543c5 a {
            display: block;
        }
            .styles_featuredImageContainer__543c5:hover .styles_desktopImage__bLu91::after {
                content: '';
                display: block;
                position: absolute;
                inset: 0;
                background-color: rgba(38, 38, 38, 0.3);
            }

        .styles_featuredImageContainer__543c5:focus-within {
            box-shadow: 0 0 0 4px #FFDA0A;

            /* Visible in Windows high-contrast themes: https://stackoverflow.com/a/52616313 */
            outline: 4px dotted transparent;
        }

        @media (min-width: calc(1024 * 1px)) {

    .styles_featuredImageContainer__543c5 {
            margin-bottom: 0
    }

            .styles_featuredImageContainer__543c5:nth-child(n + 3) {
                display: block;
            }
        }
}

@layer overrides {
    .styles_descriptionText__S6nqT {
        backdrop-filter: blur(1px);
        background-color: rgba(255, 255, 255, 0.7);
        bottom: 0;
        color: #262626;
        font-size: 1.125rem;
        font-weight: bold;
        padding: 16px 4px;
        position: absolute;
        text-align: center;
        width: 100%;
    }
}

@layer components {
  .styles_wrapper__eQ3KO {
    display: none;
  }

    @media (max-width: calc((768 - 1) * 1px)) {
  .styles_wrapper__eQ3KO {
      display: block;
      min-width: 0
  }
    }

  .styles_triggerButton___PICE {
    background: transparent;
    border: 0;
    padding: 0;
    font-size: 0;
    cursor: pointer;

    /* 16px - 4px to account for icon padding */
    margin-right: 12px;
  }
}

@layer overrides {
  .styles_triggerIcon__NtVKL {
    color: #262626;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

    .styles_triggerIcon__NtVKL:hover {
      color: #747474;
    }
}

@layer components {
  .styles_navTitle__HGw5U {
    margin-bottom: 8px;
  }

  .styles_navSecondaryList__wEmy1 {
    column-count: 2;
    column-gap: 24px;
    margin-top: 16px;
    list-style: none;
    padding: 0;
  }

  .styles_navListItem__YXf9P {
    cursor: pointer;
    margin-bottom: 16px;
    vertical-align: top;
    width: 100%;
  }

    .styles_navListItem__YXf9P:hover {
      text-decoration: underline;
    }

  .styles_navLink__HuwUR {
    color: #262626;
    text-decoration: none;
  }

  .styles_cookiesButton__M5c_h {
    color: #262626;
    text-decoration: none;
    font-family: inherit;
    font-size: inherit;
    background: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
  }

    .styles_cookiesButton__M5c_h:hover {
      text-decoration: underline;
    }
}

@layer components {
  .styles_container__CVubd {
    margin-top: 32px;
    min-width: 33.3%;
    max-width: 33.3%;
    display: none;    
  }
  
    @media (min-width: calc(1024 * 1px)) {
  .styles_container__CVubd {
      display: block;
      flex-direction: row;
      margin-top: 0;
      padding: 24px 16px    
  }
    }

  .styles_featuredContainer__rTOmn {
    display: grid;
    grid-template:
      'a' auto
      'b' auto;
    gap: 16px;
    justify-content: center;
  }
  
    @media (min-width: calc(1024 * 1px)) {

  .styles_featuredContainer__rTOmn {
      grid-template:
        'a b' auto
        'c d' auto;
      grid-template-columns: 1fr 1fr
  }
    }
  
    .styles_featuredContainer__rTOmn a {
      display: flex;
      position: relative;
    }

  .styles_heading__HGpe1 {
    margin-bottom: 16px;
  }
}
@layer components {
  .styles_categoryListContainer__IXnHC {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding-right: 32px;
    justify-content: space-between;
  }

  .styles_categoryListItemsContainer__largeList__lzgGx {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(6, auto);
    grid-auto-columns: 1fr;
  }

  .styles_categoryListItemsContainer__smallList__El2Sd {
    grid-template-rows: repeat(8, auto);
  }

  .styles_categoryListitem__Fct3o {
    list-style: none;
    border-bottom: 1px solid #F3F3F3;
  }

  .styles_categoryListLink__g2_tb {
    display: block;
    text-decoration: none;
    padding: 16px;
  }

    .styles_categoryListLink__g2_tb:hover {
      background-color: #F3F3F3;
    }

    .styles_categoryListLink__g2_tb:focus {
      box-shadow: inset 0 0 0 4px #FFDA0A;

      /* Visible in Windows high-contrast themes: https://stackoverflow.com/a/52616313 */
      outline: 4px dotted transparent;
    }
}

@layer overrides {
  .styles_categoryListTitle__QqBG_ {
    padding: 24px 16px;
  }

  .styles_categoryListItemText__UUeyQ {
    color: #262626;
  }

  .styles_categoryListFooter__XsvqQ {
    text-decoration: none;
  }

    .styles_categoryListFooter__XsvqQ:hover {
      background-color: #F3F3F3;
    }
}

@layer components {
  .styles_menuList__5N6Ea {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: start;
  }

  .styles_hoverContainer__2cs5G {
    display: none;
    position: absolute;
    left: calc(16px * -1);
    right: calc(16px * -1);
    padding: 0 16px 24px;
    top: 53px;
    background: #FFFFFF;
    border-top: 1px solid #EEEEEE;
  }

    .styles_hoverContainer__2cs5G::after {
      content: '';
      display: none;
      position: absolute;
      background: rgba(38, 38, 38, 0.3);
      height: 100vh;
      right: 0;
      left: 0;
      z-index: -1;
      pointer-events: none;
    }

  .styles_hoverContainerActive___rSi5 {
    display: flex;
  }

    .styles_hoverContainerActive___rSi5::after {
      display: block;
    }

  .styles_container__euUot {
    display: block;
    margin: 0 auto;
    padding: 0 16px;
    border-bottom: 1px solid #EEEEEE;
  }
  
    @media (max-width: calc((768 - 1) * 1px)) {

  .styles_container__euUot {
      display: none
  }
    }

  .styles_navCategoryListWide__eTk5R {
    width: 66.6%;
  }

  .styles_navCategoryListNarrow__EhPQ_ {
    width: 33.3%;
  }
}

@layer overrides {
  .styles_menuListItemButton__hpXd9 {
    background: #FFFFFF;
    padding: 16px;
    border: 0;
    color: var(--textColor);
    cursor: pointer;
  }
  
    .styles_menuListItemButton__hpXd9:hover,.styles_menuListItemButton__hpXd9:focus-within {
      color: #FFFFFF;
      background: var(--hoverBg, #262626);
    }
  
    .styles_menuListItemButton__hpXd9:focus {
      box-shadow: inset 0 0 0 4px #FFDA0A;

      /* Visible in Windows high-contrast themes: https://stackoverflow.com/a/52616313 */
      outline: 4px dotted transparent;
    }
      .styles_menuListItem__SYKnY:hover .styles_menuListItemButton__hpXd9, .styles_menuListItem__SYKnY:focus-within .styles_menuListItemButton__hpXd9 {
        color: #FFFFFF;
        background: var(--hoverBg, #262626);
      }
}
@layer components {
  .styles_container__MuXnh {
    display: grid;
    gap: 8px;
    align-items: center;
    grid-template-columns: auto 1fr;
  }

    @media (min-width: calc(768 * 1px)) {
  .styles_container__MuXnh {
      gap: 16px
  }
    }
}

@layer overrides {
    .styles_username__zh8fr :hover {
      text-decoration: underline;
    }

  .styles_feedbackWithMargin__prjvT {
    margin: 4px 0;
  }
}
@layer components {
  .styles_featureBanner__zD8uM {
    position: relative;
    display: flex;
    height: 124px;
    justify-content: space-between;
    margin: 16px 0;
    background-color: #3d37bd;
    border-radius: 8px;
    overflow: hidden;
  }

  .styles_featureBannerText__95rPg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
  }

    @media (max-width: calc((375 - 1) * 1px)) {

  .styles_featureBannerText__95rPg {
      padding: 4px
  }
    }

  .styles_text__8oETz {
    color: #FFFFFF;
  }

  .styles_betaTag__1hn6k {
    color: #262626;
    position: absolute;
    right: 8px;
    top: 8px;
    border-radius: 12px;
    background-color: #FFFFFF;
    padding: 4px;
    line-height: 1rem;
  }
}

@layer components {
  .styles_container__XwkyS {
    display: flex;
    align-items: center;
    color: #262626;
  }

    .styles_container__XwkyS p,
    .styles_container__XwkyS span {
      color: inherit;
    }

  .styles_shippingIcon__XklPU {
    color: #262626;
    margin: 0 24px;
  }
}

@layer components {
  .styles_form__D2Wca {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .styles_panel__LvoQy {
    width: 100%;
  }

    @media (min-width: calc(768 * 1px)) {

  .styles_panel__LvoQy {
      margin-bottom: 24px
  }
    }

  .styles_orderSummaryPanel__0p_NK {
    min-height: 146px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

    @media (min-width: calc(768 * 1px)) {

  .styles_orderSummaryPanel__0p_NK {
      display: none
  }
    }

  .styles_loadingContainer__Y465k {
    display: flex;
    align-items: center;
    inset: 0;
  }
}

@layer components {
  .styles_unavailable__TQ2W1 {
    color: #747474;
  }
}

@layer components {
  .styles_title__doLHl {
    margin-bottom: 24px;
  }

  .styles_showMoreButton__Dj35g {
    margin-bottom: 16px;
    font-size: 0.875rem;
    padding: 0;
  }

  .styles_invalidShippingAddress__GSH2f {
    color: #E20020;
    margin: 16px 0;
  }
}

@layer components {
  .styles_addressInputWrapper__lvr5K {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

    /* This is gross, no easy way to target the padding of the Input component */
      .styles_addressInputWrapper__lvr5K > div > div {
        padding-bottom: 0;
        margin-bottom: 0;
      }
}

@layer components {
    .styles_wrapper__riDNh {
        position: relative;
        width: 100%;
    }

    .styles_resultsContainer__nlg1p {
        max-height: var(--maxHeight);
        overflow-y: scroll;
        position: absolute;
        left: var(--left);
        right: var(--right);
        top: var(--top);
        border-radius: 8px;
        border: 1px solid #D7D7D7;
        background: #FFFFFF;
        box-shadow: 0 2px 8px 0 #747474;
        z-index: calc(calc(0 + 1) + 1);
    }

    .styles_resultItems__HneRC {
        list-style: none;
    }

    .styles_resultOptionContent___FBRt {
        padding: 12px 0;
        margin: 0 16px;
        border-bottom: 1px solid #F3F3F3;
        font-size: 0.75rem;
        color: #747474;
        line-height: 1.5;
    }

        .styles_resultOptionContent___FBRt:focus {
            border-color: transparent;
        }

    .styles_resultOption__NIjcg {
        cursor: var(--cursor);
        opacity: var(--opacity);
    }

        .styles_resultOption__NIjcg:hover {
            background: #F3F3F3;
        }

        .styles_resultOption__NIjcg:focus {
            box-shadow: inset 0 0 0 4px #FFDA0A;

            /* Visible in Windows high-contrast themes: https://stackoverflow.com/a/52616313 */
            outline: transparent dotted 4px;
        }

    .styles_resultOptionTextWrapper__nHgOq {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        margin-bottom: 4px;
    }

    .styles_resultOptionText__WXyzG {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .styles_resultOptionCaption__tx1tU {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .styles_resultOptionIconWrapper__F_pbW {
        width: 21px;
        height: 21px;
        margin-left: 8px;
    }
}
@layer components {
  .styles_flexContainer__rury8 {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    width: 100%;
  }

  .styles_label__3BP_D {
    margin-bottom: 8px;
  }

  .styles_textArea__nnBQZ {
    border: 1px solid #D7D7D7;
    border-radius: 2px;
    border-color: #666666;
    resize: none;
    width: 100%;
    margin-bottom: 8px;
    font-family: var(--font-GtAmericaExtended), -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    font-size: 0.875rem;
    padding: 8px;
    min-height: 105px;
    color: #262626;
  }
   
    .styles_textArea__nnBQZ::placeholder {
      color: #666666;
    }

  .styles_textAreaError__kY_r_ {
    border: 1px solid #FF2300;
  }

    .styles_textAreaError__kY_r_:focus {
        outline-color: #FF2300;
    }

  .styles_errorMessage__4_0zA {
    margin-top: 0;
  }
}

@layer components {
    .styles_container__OY3i6 {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 16px;
        bottom: 16px;
    }

        @media (max-width: calc((576 - 1) * 1px)) {
    .styles_container__OY3i6 {
            display: none
    }
        }

    .styles_messageButtonContainer__1Yiwr {
        width: 24px;
        display: flex;
        justify-content: center;
        margin-left: 4px;
    }
}

@layer overrides {
    .styles_messageButtonContainer__1Yiwr svg {
        width: 18px;
        height: 18px;
    }
}
@layer components {
   .styles_container___uFb1 {
       flex: 1 1 auto;
       overflow: auto;
       font-size: 0.875rem;
   }

       @media (max-width: calc((576 - 1) * 1px)) {
   .styles_container___uFb1 {
           padding: 0
   }
       }

       @media (max-width: calc((1024 - 1) * 1px)) {
   .styles_container___uFb1 {
           width: 100%;
           flex: none
   }
       }

    .styles_infiniteScrollContainer__XC6H7 {
        /* allows the user to scroll horizontally in the receipt list on smaller screens */

        /* min-width is the container width - scrollbar for accessibility */
        min-width: 896px;
    }

        @media (max-width: calc((576 - 1) * 1px)) {

    .styles_infiniteScrollContainer__XC6H7 {
            min-width: 0
    }
        }

    .styles_wrapper__JTFc9 {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
    }

        @media (max-width: calc((576 - 1) * 1px)) {

    .styles_wrapper__JTFc9 {
            flex-direction: column
    }
        }

    .styles_receiptsListWrapper__bdK1V {
        position: relative;
    }

    .styles_arrowWrapper__Sc8J2 {
        display: none;
    }

        @media (max-width: calc((576 - 1) * 1px)) {

    .styles_arrowWrapper__Sc8J2 {
            display: flex;
            justify-content: center;
            flex-direction: column
    }
        }

    .styles_arrowIcon__yyBbf {
        color: #262626;
    }
}
@layer components {
    .styles_wrapper__YAAG6 {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 64px 0;
        color: #747474;
        justify-content: center;
    }

    .styles_wrapper__YAAG6 p {
        max-width: 304px;
    }

    .styles_icon__eqd3V {
        margin-bottom: 8px;
    }
}
@layer components {
    .styles_loadingRow__U_r6L {
        height: 120px;
    }
}
@layer components {
    .styles_headerRow__HXSiW {
        width: 100%;
        display: flex;
        justify-content: space-between;
        background-color: transparent;
        padding: 16px 16px 16px 24px;
        color: #D7D7D7;
        border-bottom: 1px solid #D7D7D7;
        cursor: default;
    }

        @media (max-width: calc((576 - 1) * 1px)) {
    .styles_headerRow__HXSiW {
            display: none
    }
        }

        @media (max-width: calc((768 - 1) * 1px)) {
    .styles_headerRow__HXSiW {
            padding: 16px 0;
            justify-content: flex-start
    }
        }

    .styles_wrapper__SV38q {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
    }

        @media (max-width: calc((576 - 1) * 1px)) {

    .styles_wrapper__SV38q {
            flex-direction: column
    }
        }

    .styles_userInfoHeading__cF_ZH {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: unset;
        position: relative;
    }

        @media (min-width: calc(576 * 1px)) {

    .styles_userInfoHeading__cF_ZH {
            flex: 1
    }
        }

        @media (min-width: calc(768 * 1px)) {

    .styles_userInfoHeading__cF_ZH {
            width: 140px
    }
        }

        @media (min-width: calc(1200 * 1px)) {

    .styles_userInfoHeading__cF_ZH {
            width: 160px
    }
        }

    .styles_transactionDateHeading__j9MJS {
        display: flex;
        flex: 0 0 120px;
    }

        @media (max-width: calc((576 - 1) * 1px)) {

    .styles_transactionDateHeading__j9MJS {
            flex: 1
    }
        }
        @media (max-width: calc((576 - 1) * 1px)) {

    .styles_transactionDateHeading__j9MJS p {
            text-transform: uppercase;
            font-size: 0.75rem
    }
        }

    .styles_shippingProvidersHeading__bk9n8 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 0 0 160px;
    }

        @media (max-width: calc((576 - 1) * 1px)) {

    .styles_shippingProvidersHeading__bk9n8 {
            display: none
    }
        }

    .styles_shippingStatusHeading__m4YhL {
        display: flex;
        flex: 0 0 160px;
    }

        @media (max-width: calc((576 - 1) * 1px)) {

    .styles_shippingStatusHeading__m4YhL {
            flex: 1
    }
        }
        @media (max-width: calc((576 - 1) * 1px)) {

    .styles_shippingStatusHeading__m4YhL p {
            font-size: 0.75rem;
            color: #747474
    }
        }

    .styles_totalHeading__ZNKk9 {
        display: flex;
        flex: 0 0 160px;
        text-transform: capitalize;
    }

        @media (max-width: calc((576 - 1) * 1px)) {

    .styles_totalHeading__ZNKk9 {
            display: none
    }
        }

    .styles_manageHeading__7PaCl {
        display: flex;
        flex: 0 0 120px;
        text-align: right;
        text-transform: capitalize;
    }

        @media (max-width: calc((576 - 1) * 1px)) {

    .styles_manageHeading__7PaCl {
            display: none
    }
        }
}
@layer components {
    .styles_imageWrapper__RjnGD {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 24px;
        position: relative;
    }

        @media (max-width: calc((576 - 1) * 1px)) {
    .styles_imageWrapper__RjnGD {
            flex-direction: row;
            flex: none;
            padding: 0;
            margin-right: 16px
    }
        }
}
@layer components {
    .styles_wrapper__qw03f {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: unset;
        position: relative;
    }

        @media (max-width: calc((375 - 1) * 1px)) {
    .styles_wrapper__qw03f {
            flex: none
    }
        }

        @media (min-width: calc(576 * 1px)) {
    .styles_wrapper__qw03f {
            flex: 1
    }
        }

        @media (min-width: calc(768 * 1px)) {
    .styles_wrapper__qw03f {
            width: 140px
    }
        }

        @media (min-width: calc(1200 * 1px)) {
    .styles_wrapper__qw03f {
            width: 160px
    }
        }

    .styles_location__gKwbG {
        display: flex;
        flex-direction: column;
    }

        @media (max-width: calc((576 - 1) * 1px)) {

    .styles_location__gKwbG {
            display: none
    }
        }

    .styles_locationDetails__s_A_y {
        display: inline-block;
        vertical-align: bottom;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 120px;
    }

        @media (min-width: calc(576 * 1px)) {

    .styles_locationDetails__s_A_y {
            width: 140px
    }
        }

        @media (min-width: calc(1024 * 1px)) {

    .styles_locationDetails__s_A_y {
            width: unset
    }
        }
}
@layer components {
    .styles_wrapper__B25Sl {
       display: flex;
       flex: 0 0 120px;
    }

        @media (max-width: calc((576 - 1) * 1px)) {
    .styles_wrapper__B25Sl {
            flex: 1
    }
        }
        @media (max-width: calc((576 - 1) * 1px)) {

    .styles_wrapper__B25Sl p {
            text-transform: uppercase;
            font-size: 0.75rem
    }
        }

    .styles_soldOnText__LXzWM {
        display: none;
    }

        @media (max-width: calc((576 - 1) * 1px)) {

    .styles_soldOnText__LXzWM {
            display: initial
    }
        }
}
@layer components {
    .styles_wrapper__X12E_ {
        display: flex;
        flex: 0 0 160px;
    }

        @media (max-width: calc((576 - 1) * 1px)) {
    .styles_wrapper__X12E_ {
            flex: 1
    }
        }
        @media (max-width: calc((576 - 1) * 1px)) {

    .styles_wrapper__X12E_ p {
            font-size: 0.75rem;
            color: #747474
    }
        }
}
@layer components {
    .styles_container__jaDCb {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .styles_mainError__t2Z5g {
        margin: 24px 0;
    }

    .styles_subtitle__jx4Is {
        margin-top: 24px;
    }
}
@layer components {
    .styles_wrapper__y_j2_ {
        display: flex;
        flex: 0 0 160px;
        text-transform: capitalize;
    }

        @media (max-width: calc((576 - 1) * 1px)) {
    .styles_wrapper__y_j2_ {
            display: none
    }
        }
}
