@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value midGrey from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 64px 0;
        color: midGrey;
        justify-content: center;
    }

    .wrapper p {
        max-width: 304px;
    }

    .icon {
        margin-bottom: sm;
    }
}