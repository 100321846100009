@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .attributes {
    @media breakpointMdUp {
      margin-bottom: md;
    }
  }
}
