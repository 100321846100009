@value sm, md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .similarItemsSashWrapper {
    margin: sm 0 md;

    @media breakpointSmUp {
      margin: xl 0 lg;
      min-height: 180px;
    }

    li {
      margin-bottom: 0;
    }
  }

  .scrollableItemRow {
    grid-template-columns: repeat(8, 1fr);

    @media breakpointMdUp {
      grid-template-columns: repeat(6, 1fr);
    }
  }
}
