@value xs, sm, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value blue, lightGrey1, lightGrey1_r, lightGrey1_g, lightGrey1_b from "@depop/web-ui-kit/theme/default/colors.module.css";
@value borderRadiusSmall from "@depop/web-ui-kit/theme/default/border.module.css";
@value breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .activeFiltersContainer {
    display: flex;
    flex-wrap: wrap;
    gap: md;
    padding: 0 lg;
    width: 100%;

    @media breakpointLgUp {
      padding: 0;
    }
  }

  .resetAllButton {
    display: none;

    @media breakpointLgUp {
      margin-left: sm;
      display: block;
      font-size: 12px;
      color: blue;
    }
  }
}
