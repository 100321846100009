@value sm, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value white, yellow, black, lightGrey2 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value fontFamilyBody from "@depop/web-ui-kit/theme/default/fonts.module.css";

@layer components {
  .container {
    position: absolute;
    top: md;
    right: md;
    display: flex;
    flex-direction: column;
    gap: sm;
    z-index: 1;

    @media breakpointMdDown {
      top: auto;
      bottom: sm;
      right: sm;
      flex-direction: column-reverse;
    }
  }

  .buttonInteractionWrapper {
    position: relative;
    width: 44px;
    height: 44px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 8px rgba(116, 116, 116, 0.3);
    overflow: hidden;

    button {
      margin: 0;

      /* Makes the button element expand to fill the whole area of the circular div */
      padding: lg;
    }

    svg {
      width: 24px;
    }

    & > * {
      opacity: 1;
      transition: 0.3s ease opacity;
    }

    p {
      position: absolute;
      bottom: 2px;
      text-align: center;
    }

    &:has(p) {
      button {
        position: relative;
        top: -3px;
      }
    }

    &:focus-within,
    &:active {
      box-shadow: 0 0 0 2px yellow;
      outline-color: transparent;
      outline-width: 2px;
    }

    &:hover {
      & > * {
        opacity: 0.6;
      }
    }
  }

  .interactionButton {
    cursor: pointer;
    font-size: 16px;
    font-family: fontFamilyBody;
    font-weight: normal;
    background-color: transparent;
    color: black;
    border: none;
    line-height: 1.25;
    padding: 0;
    margin-right: 8px;
    display: inline-flex;

    &:hover {
      background: transparent;
      border: none;
      text-decoration: underline;
    }

    &:disabled {
      border: none;
      cursor: not-allowed;
      background-color: white;
    }

    @media breakpointMdDown {
      margin-right: 4px;
    }
  }
}
