@value md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value governorBay from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer component {
  .ctaWrapper {
    margin: lg 0 md;

    a {
      text-decoration: none;
      color: governorBay;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
