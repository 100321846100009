@value breakpointMdUp, breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value sm, md, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value blue from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .scrollableItemRow {
    grid-template-columns: repeat(4, 1fr);

    @media breakpointMdUp {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  .attributesSkeleton {
    display: none;

    @media breakpointMdUp {
      display: block;
    }
  }

  .segments {
    .segment {
      margin-bottom: md;

      @media breakpointMdUp {
        margin-bottom: xl;
      }
    }

    .segment:last-child {
      margin-bottom: 0;
    }
  }

  .seeMoreText {
    color: blue;
  }

  .modalProductListContainer {
    padding: 0 xl xl;

    li {
      list-style: none;
    }
  }
}
