@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdUp, breakpointMdDown, breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value lightGrey1 from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .img {
    width: 100%;
    margin-bottom: md;

    @media breakpointMdUp {
      margin-bottom: 0;
    }
  }

  .mobileViewContainer {
    margin: 0 -16px;

    @media breakpointMdUp {
      display: none;
    }
  }

  .desktopViewContainer {
    position: relative;
    display: block;

    @media breakpointMdDown {
      display: none;
    }
  }

  .imageContainer {
    width: 100%;
    position: relative;
    min-width: 100%;
    background-color: lightGrey1;

    @media breakpointMdUp {
      margin-bottom: sm;
    }
  }

  .imageContainer img {
    display: block;
  }

  .imageItem {
    background-image: var(--background-image);
    background-size: cover;
  }

  .imageItemNonSquare {
    height: 100%;
    object-fit: contain;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}
