@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: md;
    margin-top: md;
    grid-row-gap: md;
  }

  .postCodeInput {
    margin-top: md;
  }
}

