@value xs, sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .container {
    display: grid;
    gap: sm;
    align-items: center;
    grid-template-columns: auto 1fr;

    @media breakpointMdUp {
      gap: md;
    }
  }
}

@layer overrides {
  .username {
    :hover {
      text-decoration: underline;
    }
  }

  .feedbackWithMargin {
    margin: xs 0;
  }
}