@value md, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value blue from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .container {
    margin-bottom: xl;

    @media breakpointMdDown {
      display: none;
    }
  }

  .headingRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: md;
  }

  .seeMoreButton {
    background: none;
    cursor: pointer;
    border: none;
    padding: 0;
    color: blue;
  }

  .feedbackList {
    list-style: none;

    li:not(:last-child) {
      margin-bottom: md;
    }
  }

  .placeholderList {
    list-style: none;
    
    li {
      margin-bottom: md;
    }

    li:last-child {
      margin-bottom: 0;
    }
  }
}
